import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Divider, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {useDispatch, useSelector} from "react-redux";
import {getPosOrderHistory, setSelectedOrder} from "../../../../../redux/actions/pos/order";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OrderDetailModal from "../../mainComponents/orderDetailModal";
import FinalPaymentModal from "../../mainComponents/finalPaymentModal/finalPaymentModal";
import SnackBar from "../../mainComponents/snackBar";

const RecentOrderHistory = () => {
    const dispatch = useDispatch();
    const ordersHistory = useSelector(state => state.pos.posOrdersHistory);
    const locationData = useSelector(state => state.pos.locationData);
    const selectedOrder = useSelector(state => state.pos.selectedOrder);
    const isModifyOrder = useSelector(state => state.pos.isModifyOrder);
    const [clickedOrders, setClickedOrders] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [openFinalizeSale, setOpenFinalizeSale] = useState(false);
    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        message: '',
        severity: ''
    })


    const getPosOrders = async () => {
        await dispatch(getPosOrderHistory())
    }

    useEffect(() => {
        getPosOrders();
    }, []);

    const handleItemClick = (orderId) => {
        if (clickedOrders.includes(orderId)) {
            setClickedOrders(clickedOrders.filter(order => order !== orderId))
        } else {
            setClickedOrders([...clickedOrders, orderId]);
        }
    }

    const handleOpenOrderDetailModal = async (order) => {
        if (order.order_id === ((selectedOrder && selectedOrder.order_id) ? selectedOrder.order_id : "")) {
            console.log(order.order_id === selectedOrder.order_id)
            return
        }
        if (isModifyOrder) {
            setSnackbarParams({
                open: true,
                message: "Please, cancel modifying order first to select another order",
                severity: 'error'
            })
            return;
        }
        await dispatch(setSelectedOrder(order));
    }

    return(
        <>
            {(ordersHistory.loading || !locationData || !locationData.loc_id) ?
                <Stack sx={{backgroundColor: grey[200], flexGrow: 1, padding: '0.5rem', alignItems: 'center', justifyContent: 'center'}}>
                    <CircularProgress color='success' size={30}/>
                </Stack> :
                <Stack sx={{backgroundColor: grey[200], flexGrow: 1, padding: '0.5rem', rowGap: '0.25rem', maxHeight: {xs: '100%', xl: 'calc(100vh - 362.5px)'}, height: '100%', overflow: 'auto'}}>
                    {(ordersHistory && ordersHistory.data && ordersHistory.data.length > 0) ? (
                        ordersHistory.data.map((order, index) => (
                            <Stack onDoubleClick={()=> setOpenModal(true)} onClick={()=> handleOpenOrderDetailModal(order)} key={index} sx={{backgroundColor: (selectedOrder?.order_id ? (order.order_id === selectedOrder.order_id ? grey[300] : 'white') : "white"), borderRadius: '4px', padding: '0.5rem', alignItems: 'center', transition: '0.5s', cursor: 'pointer'}}>
                                <Typography variant='body2' sx={{marginX: 'auto', fontWeight: '600'}}>Order#: {order.order_id}</Typography>
                                <Divider sx={{width: '95%', marginX: 'auto', marginY: '0.25rem'}}/>
                                <Stack direction='row' sx={{columnGap: '0.5rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                    <Stack>
                                        <Typography variant='body2' sx={{textTransform: 'capitalize'}}>Type: {order.order_type ? order.order_type : 'Dine In'}</Typography>
                                        {(clickedOrders.includes(order.order_id) && !order.order_type) && (
                                            <>
                                                <Stack direction='column' alignItems='start'>
                                                    <Typography variant='body2' fontWeight={600}>Session#:</Typography>
                                                    <Typography variant='body2'>{order.session_id}</Typography>
                                                </Stack>
                                                <Stack direction='column' alignItems='start'>
                                                    <Typography variant='body2' fontWeight={600}>Table:</Typography>
                                                    <Typography variant='body2'>{order.Table.table_name}</Typography>
                                                </Stack>
                                            </>
                                        )}
                                    </Stack>
                                    <ArrowDropDownIcon onClick={()=> handleItemClick(order.order_id)} sx={{transform: (clickedOrders.includes(order.order_id) ? 'rotate(180deg)' : 'rotate(0deg)'), transition: '0.5s'}} />
                                </Stack>
                            </Stack>
                        ))
                    ) : (
                        <Typography variant='h6'>No Orders Found</Typography>
                    )}
                </Stack>
            }
            <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
            {openModal && <OrderDetailModal setOpenModal={setOpenModal} openModal={openModal} order={selectedOrder}
                                            setOpenFinalizeSale={setOpenFinalizeSale}/>}
            {openFinalizeSale && <FinalPaymentModal openModal={openFinalizeSale} setOpenModal={setOpenFinalizeSale} order={selectedOrder} setSnackbarParams={setSnackbarParams}/>}
        </>
    )
}

export default RecentOrderHistory;