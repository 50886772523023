import React from 'react'
import SearchComponent from "../../../components/mainScreen/rightSectionComponents/searchComponent";
import MenuCategoriesDropDown from "../../../components/mainScreen/rightSectionComponents/menuCategoriesDropDown";
import {Stack, useMediaQuery, useTheme} from "@mui/material";

const UpperSectionComponents = () => {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('xl'));

    return (
        <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.5rem'}}>
            <SearchComponent />
            {md && <MenuCategoriesDropDown/>}
        </Stack>
    )
}

export default UpperSectionComponents;