import React from 'react';
import {Stack} from "@mui/material";
import RecentOrdersSearch from "../../components/mainScreen/leftSectionComponents/recentOrdersSearch";
import RecentOrderHistory from "../../components/mainScreen/leftSectionComponents/recentOrderHistory";
import OrderPlacementButtons from "../../components/mainScreen/leftSectionComponents/orderPlacementButtons";

const LeftSection = () => {
    return(
        <Stack sx={{backgroundColor: 'white', height: '100%', borderRadius: '0.25rem', width: '200px', minWidth: '200px'}}>
            <RecentOrdersSearch />
            <RecentOrderHistory />
            <OrderPlacementButtons />
        </Stack>
    )
}

export default LeftSection;