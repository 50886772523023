import CONSTANTS from "../constants";
export const createDirectOrder = async ({
                                          user_id,
                                          order_type,
                                          location_id,
                                          session_date,
                                          start_time,
                                          end_time,
                                          session_id,
                                          customerType,
                                          order_time,
                                          order_date,
                                          order_type_id,
                                          pos_order,
                                          order_variant,
                                          discount_id,
                                          total_discount,
                                          totalPrice,
                                          comment,
                                          qrcode,
                                          table_no,
                                          total_items,
                                          qrcodedata,
                                          order_owner,
                                          promocode_id,
                                          payment_status_id,
                                          order_tax,
                                          session_owner,
                                          menu_items,
                                          walk_in,
                                        }) => {
  let data = {
    user_id,
    order_type,
    location_id,
    session_date,
    start_time,
    end_time,
    session_id,
    order_time,
    pos_order,
    order_owner,
    order_date,
    order_type_id,
    order_variant,
    discount_id,
    total_discount,
    totalPrice,
    comment,
    ...customerType,
    qrcode,
    table_no,
    total_items,
    qrcodedata,
    promocode_id,
    payment_status_id,
    order_tax,
    session_owner,
    menu_items: menu_items,
    walk_in
  };

  let response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.DIRECT_ORDERS.DEFAULT}`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: { ...CONSTANTS.REQUEST_HEADERS },
      }
  );

  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Create Order: ${response}`);
};
