import React, {useState} from 'react'
import {Grid, Stack} from "@mui/material";
import GridItem from "./gridItem";
import {useSelector} from "react-redux";
import ItemModal from "../../mainComponents/itemModal";

const MenuItemSelectionComponent = ({locationData}) => {
    const menuData = useSelector((state)=> state.menu.menuData.data)
    const selectedCategory = useSelector((state)=> state.pos.selectedCategory)
    const [selectedItem, setSelectedItem] = useState(null);
    const [openItemModal, setOpenItemModal] = useState(false);

    return (
        (menuData && menuData.menu_data.length) > 0 ?
            <>
                <Stack sx={{overflow: 'auto', height: '100%', padding: '0.25rem 0.5rem', flexGrow: 1, maxHeight: 'calc(100vh - 125px)'}}>
                    <Grid container spacing={2}>
                        {selectedCategory.selected ?
                            (
                                selectedCategory.Menu.map((menuItem, index) => (
                                    <Grid key={index} item xs={6} sm={4} md={6} lg={4} xl={4} xxl={3} xxxl={2.4} alignItems={'stretch'}>
                                        <GridItem setOpenModal={setOpenItemModal} setSelectedItem={setSelectedItem} menuItem={menuItem} currencySymbol={locationData?.Currency?.currency_symbol}/>
                                    </Grid>
                                ))
                            ) :
                            (menuData.menu_data.map((menuCategory) => (
                                menuCategory.Menu.map((menuItem, itemIndex) => (
                                    <Grid key={itemIndex} item xs={6} sm={4} md={6} lg={4} xl={4} xxl={3} xxxl={2.4} alignItems={'stretch'}>
                                        <GridItem setOpenModal={setOpenItemModal} setSelectedItem={setSelectedItem} menuItem={menuItem} currencySymbol={locationData?.Currency?.currency_symbol}/>
                                    </Grid>
                                )))))
                        }
                    </Grid>
                </Stack>
                {openItemModal && <ItemModal restaurantData={locationData} openModal={openItemModal} setOpenModal={setOpenItemModal}
                                             item={selectedItem} setModalItem={setSelectedItem}/>}
            </>
            :
            <></>
    )
}

export default MenuItemSelectionComponent;