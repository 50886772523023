import React from 'react'
import ButtonsComponent
    from "../../../components/mainScreen/middleSectionComponents/bottomSectionComponents/buttonsComponent";

const BottomSectionComponents = () => {
    return(
        <>
            <ButtonsComponent />
        </>
    )
}

export default BottomSectionComponents;