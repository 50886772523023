import CONSTANTS from "../../../../constants";

export function setCustomerDetails(customer) {
    return (dispatch) => {
        dispatch({type: CONSTANTS.ACTIONS.POS.SAVE_CUSTOMER_DATA, data: customer});
    }
}

export function setCustomerType(customer_type) {
    return (dispatch) => {
        dispatch({type: CONSTANTS.ACTIONS.POS.CUSTOMER_TYPE, data: customer_type});
    }
}