import React from 'react';
import {Table, Stack, TableHead, TableBody, TableCell, TableRow, TextField, IconButton, Tooltip, Divider, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {useDispatch, useSelector} from "react-redux";
import numeral from "numeral";
import {Add, Close, Remove} from "@mui/icons-material";
import {removeItem} from "../../../../../../redux/actions/cart";

const tableHeaders = [
    "Item", "Price", "Qty", "Discount", "Total", "Actions"
]

const CartComponent = () => {
    const cart = useSelector((state) => state.cart.cartMenu);
    const locationData = useSelector(state => state.pos.locationData);
    const dispatch = useDispatch();

    const handleIncrement = (itemIndex) => {
        const updatedCart = cart.cart.map((item, index) => {
            if (index === itemIndex) {
                const updatedItem = {
                    ...item,
                    itemCount: item.itemCount + 1,
                    order_menu_tax:
                        (item.order_menu_tax / item.itemCount) * (item.itemCount + 1),
                    MenuOptions: item.MenuOptions.map((menuOption) => ({
                        ...menuOption,
                        Option_Values: menuOption.Option_Values.map((optionValue) => {
                            let initialOptionCount = optionValue.initialOptionCount;

                            if (initialOptionCount === undefined) {
                                initialOptionCount = optionValue.optionCount;
                            }

                            const updatedOptionValue = {
                                ...optionValue,
                                optionCount: optionValue.optionCount + initialOptionCount,
                                initialOptionCount,
                            };

                            return updatedOptionValue;
                        }),
                    })),
                };

                return updatedItem;
            }
            return item;
        });

        dispatch(removeItem({ itemIndex, items: updatedCart }));
    };

    const handleDecrement = (itemIndex) => {
        const updatedCart = cart.cart.map((item, index) => {
            if (index === itemIndex) {
                const updatedItem = {
                    ...item,
                    itemCount: item.itemCount > 1 ? item.itemCount - 1 : 1,
                    order_menu_tax:
                        (item.order_menu_tax / item.itemCount) * (item.itemCount - 1),
                    MenuOptions: item.MenuOptions.map((menuOption) => ({
                        ...menuOption,
                        Option_Values: menuOption.Option_Values.map((optionValue) => {
                            let initialOptionCount = optionValue.initialOptionCount;

                            if (initialOptionCount === undefined) {
                                initialOptionCount = optionValue.optionCount;
                            }

                            const newOptionCount =
                                optionValue.optionCount > initialOptionCount
                                    ? optionValue.optionCount - initialOptionCount
                                    : initialOptionCount;

                            return {
                                ...optionValue,
                                optionCount: newOptionCount,
                                initialOptionCount,
                            };
                        }),
                    })),
                };
                return updatedItem;
            }
            return item;
        });

        dispatch(removeItem({ itemIndex, items: updatedCart }));
    }

    const formatPrice = (price) => {
        if (price >= 1000) {
            const formattedValue = numeral(price).format("0.0a");
            return formattedValue;
        } else {
            return numeral(price).format("0.00");
        }
    };

    const calculateTotalPrice = (menuPrice, taxPercent, itemCount) => {
        const itemPrice = menuPrice || 0;
        const tax = taxPercent || 0;

        const totalPrice =
            tax > 0
                ? (itemPrice + (itemPrice * tax) / 100) * itemCount
                : itemPrice * itemCount;

        return formatPrice(totalPrice);
    };

    const calculateVariantPrice = (optionPrice, taxPercent, itemCount) => {
        const itemPrice = optionPrice || 0;
        const tax = taxPercent || 0;
        const variantPrice =
            tax > 0
                ? itemPrice * itemCount * (tax / 100) + itemPrice * itemCount
                : itemPrice * itemCount;

        return variantPrice;
    };

    const handleRemoveItem = (itemIndex) => {
        let remainItems = [];
        cart.cart.map((item, index) => {
            if (index !== itemIndex) {
                remainItems.push(item);
            }
        });

        dispatch(removeItem({ itemIndex, items: remainItems }));
    };

    return(
        <Stack sx={{border: `1px solid ${grey[400]}`, borderRadius: '0.25rem', overflow: 'hidden', flexGrow: 1}}>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            tableHeaders.map((header, index) => (
                                <TableCell align={'center'} sx={{padding: '0.5rem !important', fontWeight: '600'}} key={index}>{header}</TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cart.cart.map((item, itemIndex) => (
                        <TableRow key={itemIndex}>
                            <TableCell align={'left'} sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>
                                {item.menu_name}
                                <Stack sx={{rowGap: '0.125rem'}}>
                                    {
                                        item.MenuOptions.length > 0 && (item.MenuOptions.map((menu_option, index) => (
                                            <React.Fragment key={menu_option.option_id}>
                                                {index !== 0 && <Divider />}
                                                <Typography variant="body2" color="textSecondary" sx={{fontSize: '0.75rem', fontWeight: 'bold'}}>
                                                    {menu_option.option_name}
                                                </Typography>
                                                {menu_option.Option_Values.map((menu_option_value) => (
                                                    <Typography key={menu_option_value.option_id} variant="body2" color="textSecondary" sx={{fontSize: '0.7rem', textTransform: 'capitalize'}}>
                                                        {`${menu_option_value.optionCount}x ${menu_option_value.value} = ${locationData.Currency.currency_symbol}${menu_option_value.price === 0
                                                            ? "Free" :
                                                            calculateVariantPrice(
                                                                menu_option_value.price,
                                                                menu_option_value.order_item_tax_percentage,
                                                                menu_option_value.optionCount
                                                            ).toFixed(2)
                                                        }`}
                                                    </Typography>
                                                ))}
                                            </React.Fragment>
                                        )))
                                    }
                                </Stack>
                            </TableCell>
                            <TableCell align={'center'} sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>{locationData.Currency.currency_symbol}{calculateTotalPrice(item.menu_price, item.menu_tax_percentage, 1)}</TableCell>
                            <TableCell align={'center'} sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>
                                <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.25rem', justifyContent: 'center'}}>
                                    <IconButton onClick={()=> handleIncrement(itemIndex)} size='small'>
                                        <Add sx={{height: '1.25rem', width: '1.25rem'}}/>
                                    </IconButton>
                                    {item.itemCount}
                                    <IconButton onClick={()=> handleDecrement(itemIndex)} size='small'>
                                        <Remove sx={{height: '1.25rem', width: '1.25rem'}}/>
                                    </IconButton>
                                </Stack>
                            </TableCell>
                            <TableCell align={'center'} sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>
                                <TextField
                                    disabled
                                    inputProps={{
                                        style: {
                                            padding: '0.5rem 0.65rem'
                                        }
                                    }} variant='outlined' size='small' placeholder={'Amt'} sx={{width: '50px'}}/>
                            </TableCell>
                            <TableCell align={'center'} sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>{locationData.Currency.currency_symbol}{calculateTotalPrice(item.menu_price, item.menu_tax_percentage, item.itemCount)}</TableCell>
                            <TableCell align={'center'} sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>
                                <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.25rem', justifyContent: 'center'}}>
                                    {/*<Tooltip title={`Edit ${item.menu_name}`}>*/}
                                    {/*    <IconButton size='small'>*/}
                                    {/*        <Edit sx={{height: '1.25rem', width: '1.25rem'}}/>*/}
                                    {/*    </IconButton>*/}
                                    {/*</Tooltip>*/}
                                    <Tooltip title={`Remove ${item.menu_name}`}>
                                        <IconButton onClick={()=> handleRemoveItem(itemIndex)} size='small'>
                                            <Close sx={{height: '1.25rem', width: '1.25rem'}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Stack>
    )
}

export default CartComponent;