import React, {useState} from 'react';
import {Button, Stack} from "@mui/material";
import {blue, green, orange, purple, red} from "@mui/material/colors";
import ProductsDrawer from "../mainScreen/productsDrawer";
import LeftSectionDrawer from "../mainScreen/leftSectionDrawer";

const buttonStyles = {
    textTransform: 'capitalize',
    borderRadius: '0.25rem',
    padding: '0.35rem 0.5rem',
    textAlign: 'center',
    lineHeight: '1.5rem',
    flexGrow: 1,
    color: 'white',
    cursor: 'pointer',
    boxShadow: '0 0 5px rgba(0,0,0,0.25)',
}

const buttons = [
    {
        label: 'Ongoing Orders',
        backgroundColor: red[400],
        hoverBackgroundColor: red[600]
    },

    {
        label: 'Cart',
        backgroundColor: orange[400],
        hoverBackgroundColor: orange[600]
    },

    {
        label: 'Products',
        backgroundColor: purple[400],
        hoverBackgroundColor: purple[600]
    },

    {
        label: 'Others',
        backgroundColor: green[400],
        hoverBackgroundColor: green[600]
    }
]

const SmallScreenNavbar = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openSideDrawer, setOpenSideDrawer] = useState(false);

    const handleButtonClick = (index) => {
        if (index === 2) {
            setOpenDrawer(true)
        } else if (index === 0) {
            setOpenSideDrawer(true)
        }
    }

    return(
        <>
            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', flexGrow: 1, columnGap: '0.5rem'}}>
                {
                    buttons.map((item, index) => (
                        <Button onClick={()=> handleButtonClick(index)} variant='contained' key={index} sx={{...buttonStyles, backgroundColor: item.backgroundColor, '&:hover': {backgroundColor: item.hoverBackgroundColor}}}>
                            {item.label}
                        </Button>
                    ))
                }
            </Stack>
            <ProductsDrawer openDrawer={openDrawer}/>
            <LeftSectionDrawer open={openSideDrawer} setOpenDrawer={setOpenSideDrawer} />
        </>
    )
}

export default SmallScreenNavbar;