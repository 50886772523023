import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Badge,
  Label,
  FormGroup,
  Input,
} from "reactstrap";

import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import OrderService from "../../services/OrderService";
import ViewHelper from "../../helpers/ViewHelper";
import TimeHelper from "../../helpers/TimeHelper";
import CONSTANTS from "../../constants";
import "../../assets/css/orders.scss";

import { updateNavBarNotificationStatus } from "../../redux/actions/notifications";
import ResponseList from "../survey_response/responseList";
import { X } from "react-feather";
import toast, { Toaster } from "react-hot-toast";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import LocationService from "../../services/LocationService";
import { getLocation } from "../../redux/actions/locations";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

class PosOrdersDetail extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
  }

  state = {
    loading: true,
    location: null,
    orderSessionDetail: null,
    sessionId: null,
    orderMenuTax: null,
    showPrint: false,
    showEmailModal: false,
    saving: false,
    customerEmail: null,
    orderStatuses: null,
    selectedStatus: "",
  };
  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const location = LocationService.getLocation();
    console.log(location, ":::::::");
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id, initialize = true, fetchSesionsOrder = true) {
    this.setState({ loading: initialize, refreshing: true });
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      await this.props.getLocation(loc_id);
    }

    let sessionId = this.props.match.params.id;
    console.log("loc_id", loc_id);
    console.log("sessionId", sessionId);
    let sessionDetail = await OrderService.getSingleDirectOrder(sessionId);

    console.log("SESSIONDETAI", sessionDetail);

    this.setState({
      location: JSON.parse(JSON.stringify(this.props?.location)),
      orderSessionDetail: sessionDetail.details,
      sessionId: sessionDetail?.details.order_id,
      loading: false,
      orderMenuTax: sessionDetail?.order_menu_tax,
      orderStatuses: sessionDetail?.details[0].PrinterStatus,
      orderId: sessionDetail?.details[0].order_id,
    });
  }

  handleStatusChange = (e) => {
    console.log("SELECTEDASTATUS", e.target.value);
    this.setState({ selectedStatus: e.target.value });
  };

  updateOrderStatus = async () => {
    const { selectedStatus, orderId } = this.state;
    console.log("orderId", orderId);
    if (selectedStatus) {
      try {
        const response = await OrderService.updateOrderStatus(
          selectedStatus,
          orderId
        );
        if (response) {
          toast.success("Status updated successfully");
          // Refresh the data to reflect the new status
          await this.refreshData(this.state.location.loc_id);
        } else {
          toast.error("Failed to update status");
        }
      } catch (error) {
        toast.error("Error updating status: " + error.message);
      }
    } else {
      toast.error("Please select a status");
    }
  };

  renderOrderDetailViewTop(directOrder) {
    console.log("directOrder-----------", directOrder);

    const selectStyle = {
      borderRadius: "8px",
      padding: "0.375rem 1.75rem 0.375rem 0.75rem",
      backgroundColor: "#fff",
      color: "#495057",
    };

    return (
      <div className="d-flex flex-column " id="order-heading">
        {directOrder.map(
          (order, index) => (
            console.log("ORDER", order.order_status),
            (
              <div key={index} className="card ">
                <div className="card-body ">
                  <div>
                    <div className="text-uppercase  mb-3">
                      <h3>Order Id: # {order?.order_id}</h3>
                    </div>
                    <p className="h6 mb-2">
                      {TimeHelper.toFormattedDate(
                        order?.order_date,
                        "ddd, Do MMM, YYYY"
                      )}
                    </p>
                    <p className="mb-2 font-weight-bold">
                      {this.props.t("start-time-1")}: {order?.order_time}
                    </p>
                    {/* <p className="mb-1">OrderId # {order?.order_id}</p> */}

                    <p className="mb-2 font-weight-bold">
                      Order Type:
                      <span>
                        <Badge
                          color={"primary"}
                          pill
                          style={{ padding: "8px" }}
                          className="text-capitalize  "
                        >
                          {order?.order_type}
                        </Badge>
                      </span>
                    </p>
                    <p className="mb-2 font-weight-bold">
                      Order Status:
                      <span>
                        <Badge
                          color={"warning"}
                          pill
                          style={{ padding: "8px" }}
                          className="text-capitalize"
                        >
                          {order?.order_status
                            ? order.order_status
                                .replace(/_/g, " ")
                                .toLowerCase()
                                .replace(/\b\w/g, function (match) {
                                  return match.toUpperCase();
                                })
                            : ""}
                        </Badge>
                      </span>
                    </p>
                    <p className="mb-2 font-weight-bold">
                      Payment Status:
                      <span>
                        <Badge
                          color={"danger"}
                          pill
                          style={{ padding: "8px" }}
                          className="text-capitalize"
                        >
                          {order?.payment_status
                            ? order.payment_status
                                .replace(/_/g, " ")
                                .toLowerCase()
                                .replace(/\b\w/g, function (match) {
                                  return match.toUpperCase();
                                })
                            : ""}
                        </Badge>
                      </span>
                    </p>
                  </div>

                  <div className="d-flex justify-content-end">
                    <select
                      className="form-select mx-1"
                      aria-label="Order Status Select"
                      style={{
                        backgroundColor: "#f8f9fa",
                        borderColor: "#ced4da",
                        color: "#495057",
                        padding: "0.375rem 0.75rem",
                        borderRadius: "1rem",
                        width: "200px",
                        maxWidth: "100%",
                        cursor: "pointer",
                      }}
                      value={this.state.selectedStatus}
                      onChange={this.handleStatusChange}
                    >
                      <option value="" disabled>
                        {order?.order_status
                          ? order.order_status
                              .replace(/_/g, " ")
                              .toLowerCase()
                              .replace(/\b\w/g, function (match) {
                                return match.toUpperCase();
                              })
                          : "Select Status"}
                      </option>
                      <option value="placed">Placed</option>
                      <option value="accepted">Accepted</option>
                      <option value="canceled">Canceled</option>
                      <option value="preparation">Preparation</option>
                      <option value="ready_for_takeaway">
                        Ready For Takeaway
                      </option>
                      <option value="ready_for_delivery">
                        Ready For Delivery
                      </option>
                      <option value="received">Received</option>
                    </select>
                    <button
                      className="btn btn-primary mx-1"
                      onClick={this.updateOrderStatus}
                    >
                      Update Status
                    </button>
                  </div>
                </div>
              </div>
            )
          )
        )}
      </div>
    );
  }

  calculateTotalPrice = (menuPrice, taxPercent, itemCount) => {
    const itemPrice = menuPrice || 0;
    const tax = taxPercent || 0;

    const totalPrice = (itemPrice + (itemPrice * tax) / 100) * itemCount;

    return totalPrice;
  };
  calculateTotalPriceOptions = (menuPrice, taxPercent) => {
    console.log("tax percent", taxPercent);
    console.log("menuPrice", menuPrice);
    const itemPrice = menuPrice || 0;
    const tax = taxPercent || 0;

    const totalPrice = itemPrice + (itemPrice * tax) / 100;

    console.log("TOTAL_PRICE", totalPrice);
    return totalPrice;
  };

  renderMenuDetails = (order, orderMenuTax) => {
    console.log("order?.paid_status", order?.order_status);
    let discounts = 0;
    let subTotal = 0;
    let tax = 0;
    let totals = 0;
    const { isDeleteConfirmationModalOpen, orderIdToDelete, orderStatuses } =
      this.state;

    return (
      <div>
        <ConfirmationModal
          header="Delete Order Item"
          title="Are you sure you want to delete this Order Item?"
          isOpen={isDeleteConfirmationModalOpen}
          accept={() => this.handle(orderIdToDelete)}
          acceptTitle="Yes"
          reject={() =>
            this.setState({
              isDeleteConfirmationModalOpen: false,
              orderIdToDelete: null,
            })
          }
          rejectTitle="No"
        />
        <Card
          // className="shadow-sm print_order_items"
          style={{ margin: "0 auto" }}
        >
          <CardHeader>
            {" "}
            <h3>Order Details</h3>
          </CardHeader>

          <CardBody>
            {order?.map((singleorder) => {
              const orderTotals = singleorder?.OrderTotals;

              orderTotals.map((order_totals) => {
                if (order_totals?.code === "cart_total") {
                  subTotal += parseFloat(order_totals?.value);
                }

                if (order_totals?.code === "discount") {
                  discounts += parseFloat(order_totals?.value);
                }

                if (order_totals?.code === "tax") {
                  tax += parseFloat(order_totals?.value);
                }

                if (order_totals?.code === "order_total") {
                  totals += parseFloat(order_totals?.value);
                }
              });

              return singleorder?.OrderMenus?.map((menu) => {
                // Determine PrinterStatus based on menu_type
                let printerStatus = "";

                if (menu.menu_type === "bar" && orderStatuses.bar_manager) {
                  printerStatus = orderStatuses.bar_manager;
                } else if (
                  menu.menu_type === "kitchen" &&
                  orderStatuses.kitchen_manager
                ) {
                  printerStatus = orderStatuses.kitchen_manager;
                } else if (
                  menu.menu_type === "dessert" &&
                  orderStatuses.dessert_manager
                ) {
                  printerStatus = orderStatuses.dessert_manager;
                } else if (
                  menu.menu_type === "alternative_1" &&
                  orderStatuses.alternative_1
                ) {
                  printerStatus = orderStatuses.alternative_1;
                } else if (
                  menu.menu_type === "alternative_2" &&
                  orderStatuses.alternative_2
                ) {
                  printerStatus = orderStatuses.alternative_2;
                } else {
                  printerStatus = "";
                }
                console.log("PRINTERSTATUS", printerStatus);
                return (
                  <React.Fragment key={menu?.order_menu_id}>
                    <div className="order-item mb-1 d-flex justify-content-between">
                      <div className="f2 name pr-1">
                        <b>
                          {menu?.quantity}x {menu?.name} ={" "}
                          {/* {ViewHelper.toCurrency(
                          parseInt(menu.quantity) * menu.price,
                          order?.Location?.Currency?.currency_code,
                          order?.Location?.decimal_places
                        )} */}
                          {ViewHelper.toCurrency(
                            (parseInt(menu.quantity) *
                              menu?.price *
                              menu?.menu_tax_percentage) /
                              100 +
                              menu?.price * menu.quantity,
                            order?.Location?.Currency?.currency_code,
                            order?.Location?.decimal_places
                          )}
                        </b>
                        {this.renderOptions(singleorder, menu?.order_menu_id)}
                      </div>
                      <div className="f2">
                        <Badge color="success">{printerStatus}</Badge>
                      </div>
                    </div>
                    <div
                      className="order-item mb-1 d-flex justify-content-between"
                      key="comment"
                    >
                      {menu.comment && (
                        <div className="f3 name pr-1">
                          {this.props?.t("special-instructions")}:{" "}
                          <span className="font-italic text-muted">
                            {menu.comment}
                          </span>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                );
              });
            })}
            <div className="order-item border-top pt-2 d-flex justify-content-between">
              <div className="f2 name pr-1">Total Excl VAT</div>
              <div className="f2 price">
                {ViewHelper.toCurrency(
                  subTotal,
                  order?.Location?.Currency?.currency_code,
                  order?.Location?.decimal_places
                )}
              </div>
            </div>

            <div className="order-item pt-2 d-flex justify-content-between">
              <div className="f2 name pr-1">Discount</div>
              <div className="f2 price">
                {ViewHelper.toCurrency(
                  discounts,
                  order?.Location?.Currency?.currency_code,
                  order?.Location?.decimal_places
                )}
              </div>
            </div>

            {orderMenuTax?.length > 0 &&
              orderMenuTax.map((menuTax, index) => (
                <div
                  key={index}
                  className="order-item d-flex justify-content-between"
                >
                  <div className="f2 name pr-1">
                    {order?.Location?.tax_label} {menuTax?.menu_tax_percentage}%
                  </div>
                  <div className="f2 price">
                    {ViewHelper.toCurrency(
                      menuTax?.total_menu_tax,
                      order?.Location?.Currency?.currency_code,
                      order?.Location?.decimal_places
                    )}
                  </div>
                </div>
              ))}
            <div className="order-item d-flex justify-content-between pt-1">
              <div className="f2 name pr-1">
                <strong>{this.props.t("total-0")}</strong>
              </div>
              <div className="f2 price">
                <strong>
                  {ViewHelper.toCurrency(
                    totals,
                    order?.Location?.Currency?.currency_code,
                    order?.Location?.decimal_places
                  )}
                </strong>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  };
  renderOrderDetails = (order) => {
    return (
      <div className="col-md-12 col-sm-12">
        <Card style={{ margin: "0 auto" }}>
          <CardHeader>{this.props.t("customer-and-order-detail")}</CardHeader>
          <CardBody>
            <div className="mb-2 order-item mb-1 d-flex justify-content-between">
              <div>
                <strong>Order Status</strong>
              </div>
              <div>{order?.order_status?.toUpperCase()}</div>
            </div>
            <div className="mb-2 order-item mb-1 d-flex justify-content-between">
              <div>
                <strong>{this.props.t("location-name")}</strong>
              </div>
              <div>{order?.Location?.name}</div>
            </div>
            {/* Add more order details rendering as needed */}
          </CardBody>
        </Card>
      </div>
    );
  };
  renderCustomerDetails = (order) => {
    console.log("Order", order);
    return (
      <div className="col-md-12 col-sm-12 ">
        <Card style={{ margin: "0 auto" }}>
          <CardHeader>
            <h3>Delivery Information</h3>
          </CardHeader>
          <CardBody>
            <div className="mb-2 order-item mb-1 d-flex justify-content-between">
              <div>
                <strong>Email</strong>
              </div>
              <p>{(order && order[0].owner && order[0].owner.email) ? order[0].owner.email : 'No Email'}</p>
            </div>
            <div className="mb-2 order-item mb-1 d-flex justify-content-between">
            <div>
                <strong>Phone</strong>
              </div>
              <div>{(order && order[0].owner && order[0].owner.number) ? order[0].owner.number : 'No Phone Number'}</div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  };

  renderOptions(order, orderMenuId) {
    let options = order.OrderOptions.filter(
      (option) => option.order_menu_id === orderMenuId
    );
    return options.map((option) => {
      return (
        <div
          key={option.order_option_id}
          className="text-muted text-small pl-1"
        >
          {option.quantity}x{option.order_option_name} = {""}
          {option.order_option_price === 0 ? (
            <span>FREE</span>
          ) : (
            <span>
              {ViewHelper.toOptonsCurrency(
                this.calculateTotalPriceOptions(
                  option.order_option_price,
                  option.order_item_tax_percentage,

                  option.quantity
                ),

                this.state.orderSessionDetail.Location?.Currency?.currency_code,
                this.state.orderSessionDetail.Location?.decimal_places
              )}
            </span>
          )}
        </div>
      );
    });
  }

  closeEmailSendModal() {
    this.setState({
      saving: false,
      showEmailModal: false,
      customerEmail: null,
    });
  }

  async sendOrderReceipt() {
    try {
      this.setState({ saving: true });
      let loc_id = this.state.orderSessionDetail.Location.loc_id;
      let session_id = this.state.orderSessionDetail.session_id;

      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(this.state.customerEmail)) {
        toast.error(this.props.t("invalid-email-address"));
        this.setState({ saving: false });
        return;
      }

      let sendEmail = await OrderService.sendReceiptToCustomer(
        loc_id,
        session_id,
        this.state.customerEmail
      );
      toast.success(this.props.t("receipt-send-successfully"));
      this.closeEmailSendModal();
    } catch (e) {
      this.closeEmailSendModal();
      toast.error(e.toString());
      return;
    }
  }

  handleSendToCarsine = async () => {
    try {
      const session_id = this.state.sessionId;
      const response = await LocationService.getSessionCarsine(session_id);
      if (response.error) {
        toast.error(response.error);
      } else {
        toast.success("Order Sent to Caresine");
      }
    } catch (error) {
      toast.error("Error fetching session carsine: " + error.message);
    }
  };

  render() {
    const { t } = this.props;
    const {
      state: { orderSessionDetail, loading, orderMenuTax, orderStatuses },
    } = this;
    console.log("orderSessionDetail", orderSessionDetail);

    if (loading) return <SpinnerComponent />;
    return (
      <>
        <Toaster position="top-right" />

        <div className="">
          {this.renderOrderDetailViewTop(orderSessionDetail)}
          <div className="row">
            <Col md="6" sm="12">
              {this.renderMenuDetails(orderSessionDetail, orderMenuTax)}
            </Col>

            <Col md="6" sm="12">
              {/* {this.renderOrderDetails(orderSessionDetail)} */}
              {this.renderCustomerDetails(orderSessionDetail)}
            </Col>
          </div>
        </div>
        <div className="row ">
          {/* <div className="col-md-12">
            <ResponseList
              surveyResponse={orderSessionDetail?.LocationSurveyResponses}
              hideSessionbtn={true}
            />
          </div> */}
          <React.Fragment>
            <Modal
              isOpen={this.state.showEmailModal}
              className="modal-dialog-centered d-sm-margin-0 p-0"
              backdrop="static"
            >
              <div className="modal-header display-flex full-width flex-space-between">
                <h5 className="modal-title">
                  {t("share-receipt-with-customer")}
                </h5>
                <X
                  className="cursor-pointer"
                  size={20}
                  onClick={() => this.closeEmailSendModal()}
                />
              </div>

              <ModalBody>
                <FormGroup className="row">
                  <Col md={12}>
                    <Label for="question">{t("customer-email")}: </Label>
                    <Input
                      type="email"
                      id="email"
                      className="mt-2"
                      placeholder={t("survey-question")}
                      onChange={(e) =>
                        this.setState({ customerEmail: e.target.value })
                      }
                      autoComplete="off"
                      autoFocus={true}
                    />
                  </Col>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <div className="d-none d-sm-flex flex-space-between full-width">
                  <div>
                    <Button
                      color="primary"
                      onClick={() => this.sendOrderReceipt()}
                      disabled={this.state.saving}
                    >
                      {this.state.saving ? t("sending") : t("send-email-0")}
                    </Button>{" "}
                  </div>
                </div>
              </ModalFooter>
            </Modal>
          </React.Fragment>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { sessionslist } = state.orders;
  const { location } = state.locations;
  return {
    sessionslist: sessionslist?.data?.data ? sessionslist?.data?.data : [],
    totalCount: sessionslist?.data?.count ? sessionslist?.data?.count : 0,
    loading: sessionslist.loading || location.loading,
    location: location.data,
  };
}
export default withTranslation("global")(
  connect(mapStateToProps, {
    getLocation,
    updateNavBarNotificationStatus,
  })(PosOrdersDetail)
);
