import React from 'react'
import {Stack} from "@mui/material";
import PosNavbar from "../sections/navbar/posNavbar";
import MainScreen from "../sections/mainScreen/mainScreen";

const Pos = () => {
    return (
        <Stack height={'100vh'}>
            <PosNavbar />
            <MainScreen />
        </Stack>
    )
}

export default Pos;