import React from 'react';
import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog} from "@mui/material";
import {finalOrderModalStyles} from "../../../../assets/posStyles";

const NewSessionAgreementModal = ({open, setOpenModal, setSelectedTable, setOpenPaymentModal}) => {

    const payForCurrentSession = () => {
        console.log("Please, Pay")
        setOpenPaymentModal(true);
        setOpenModal({open: false, table: null});
    }

    const continueSession = () => {
        setSelectedTable(open.table);
        setOpenModal({
            open: false,
            table: null
        })
    }
    return(
        <>
            <Dialog
                open={open.open}
                keepMounted
                onClose={()=> setOpenModal(false)}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{"Start a new session?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Table already have a session started. Please,
                        complete the payment of current session to start a new session
                        or continue order with the old session?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={finalOrderModalStyles.closeButton} variant='contained' onClick={()=> payForCurrentSession()} >Pay for Current Session</Button>
                    <Button sx={finalOrderModalStyles.saveButton} variant='contained' onClick={()=> continueSession()}>Continue Old Session</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default NewSessionAgreementModal;