import React, {useState} from 'react';
import {IconButton, Stack, TextField, Typography} from "@mui/material";
import Cached from "@mui/icons-material/Cached";
import {useDispatch, useSelector} from "react-redux";
import {getPosOrderHistory, setFilteredPosOrders} from "../../../../../redux/actions/pos/order";

const RecentOrdersSearch = () => {
    const dispatch = useDispatch();
    const ordersHistory = useSelector(state => state.pos.posOrdersHistory);
    const [unfilteredOrders, setUnFilteredOrders] = useState(null);
    const [fieldValue, setFieldValue] = useState("");

    const handleSearch = (value) => {
        setFieldValue(value);

        if (fieldValue === "") {
            setUnFilteredOrders(ordersHistory);
        }

        if (value === "") {
            dispatch(setFilteredPosOrders(unfilteredOrders.data));
            return;
        }

        let finalData;
        if (unfilteredOrders) {
            const filteredData = unfilteredOrders.data.filter((item) => {
                let data = null;
                if (item.order_id.includes(value)) {
                    data = item;
                }
                if (data) {
                    return data;
                }
                return null;
            }).filter(item => item !== null);

            finalData = filteredData;
        } else {
            const filteredData = ordersHistory.data.map((item) => {
                let data = null;
                if (item.order_id.includes(value)) {
                    data = item;
                }
                if (data) {
                    return data;
                }
                return null;
            }).filter(item => item !== null);

            finalData = filteredData;
        }

        dispatch(setFilteredPosOrders(finalData));
    }

    const handleRefreshOrdersHistory = async () => {
        await dispatch(getPosOrderHistory())
    }

    return(
        <Stack sx={{padding: '0.5rem', rowGap: '0.5rem'}}>
            <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant='h6' sx={{fontSize: '1.1rem', fontWeight: '600'}}>Recent Orders</Typography>
                <IconButton size='small' onClick={()=> handleRefreshOrdersHistory()}>
                    <Cached />
                </IconButton>
            </Stack>
            <TextField
                disabled={ordersHistory.loading}
                value={fieldValue}
                fullWidth
                size="small"
                variant='outlined'
                placeholder='Table, Order No., Waiter, ....'
                onChange={(e) => handleSearch(e.target.value)}
            />
        </Stack>
    )
}

export default RecentOrdersSearch;