import React from 'react';
import {createTheme, Stack, ThemeProvider} from "@mui/material";
import {grey} from "@mui/material/colors";
import {useLocation} from "react-router-dom";

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 750,
            lg: 900,
            xl: 1200,
            xxl: 1536,
            xxxl: 1900
        },
    }
})

const POSLayout = ({children}) => {
    const location = useLocation();

    return(
        <ThemeProvider theme={theme}>
            <Stack sx={{backgroundColor: grey[300], height: (location.path === "/pos" ? '100vh' : '100%'), width: '100vw'}}>
                {children}
            </Stack>
        </ThemeProvider>
    )
}

export default POSLayout;