import axios from "axios";
import CONSTANTS from "../constants";
import LoginService from "./LoginService";

export const openRegister = async (registerData) => {
    const {token} = LoginService.getToken();

    try {
        const response = await axios.post(`${CONSTANTS.API_URL}${CONSTANTS.URLS.POS_REGISTER.SAVE_REGISTER_DETAILS}`, registerData, {
            headers: {
                ...CONSTANTS.REQUEST_HEADERS,
                'X-ACCESS-TOKEN': token
            }
        });

        if (!response || !response.data) {
            return {error: true};
        } else {
            return {data: response.data, error: false};
        }
    } catch (err) {
        return {error: true};
    }
}

export const checkRegister = async () => {
    const {token} = LoginService.getToken();

    try {
        const response = await axios.get(`${CONSTANTS.API_URL}${CONSTANTS.URLS.POS_REGISTER.CHECK_REGISTER}`, {
            headers: {
                ...CONSTANTS.REQUEST_HEADERS,
                'X-ACCESS-TOKEN': token
            }
        });

        if (!response || !response.data) {
            return {error: true};
        } else {
            return {data: response.data, error: false};
        }
    } catch (err) {
        console.log("Check Register", err);
        return {error: true};
    }
}

export const closeRegister = async (register_id, registerData) => {
    const {token} = LoginService.getToken();

    try {
        const response = await axios.put(`${CONSTANTS.API_URL}${CONSTANTS.URLS.POS_REGISTER.CLOSE_REGISTER(register_id)}`, registerData, {
            headers: {
                ...CONSTANTS.REQUEST_HEADERS,
                'X-ACCESS-TOKEN': token
            }
        });

        if (!response || !response.data) {
            return {error: true};
        } else {
            return {data: response.data, error: false};
        }
    } catch (err) {
        console.log("Close Register", err);
        return {error: true};
    }
}