import CONSTANTS from "../constants";
import LoginService from "./LoginService";
import { customFetch as fetch } from "../helpers/FetchHelper";
import axios from "axios";

export default {
    getLiveOrders,
    getOrder,
    updateStatus,
    getSessionsList,
    getSessionsDetail,
    closeLocationSession,
    deleteLocationSession,
    sendReceiptToCustomer,
    getExportSessionList,
    getDirectOrders,
    getSingleDirectOrder,
    deleteOrderId,
    updateOrderStatus,
    getPOSOrdersHistoryService,
    deletePOSOrder,
    modifyOrder
};

async function updateOrderStatus(status, orderId) {
    let { token } = LoginService.getToken();
    let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.UPDATE_DIRECT_ORDERS(
            orderId
        )}`,
        {
            method: "POST",
            body: JSON.stringify({ order_status: status }),
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
        }
    );
    if (response.ok) {
        response = await response.json();
        return response;
    }
    throw Error("Failed to Update Status.");
}

async function getLiveOrders(params) {
    let { token } = LoginService.getToken();
    // console.log(fetch);
    let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.LIVEORDER}`,
        {
            method: "POST",
            body: JSON.stringify(params),
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
        }
    );
    response = await response.json();
    if (response) return response;
    throw new Error(`Could not get live orders: ${response}`);
}

async function getOrder(locationId, orderId) {
    let { token } = LoginService.getToken();
    let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.DEFAULT(
            locationId
        )}/${orderId}`,
        {
            method: "GET",
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
        }
    );
    response = await response.json();
    if (response) return response;
    throw new Error(`Could not get live order: ${response}`);
}

async function updateStatus(locationId, orderId, statusId, reasonToCancel) {
    let { token } = LoginService.getToken();
    let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.DEFAULT(
            locationId
        )}/${orderId}`,
        {
            method: "PUT",
            body: JSON.stringify({
                status_id: statusId,
                status_history: { comment: reasonToCancel },
            }),
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
        }
    );

    response = await response.json();
    if (response) return response;
    throw new Error(`Could not update order`);
}

async function getDirectOrders(loc_id) {
    console.log("LOCATION: " + loc_id);
    // console.log("Xxxx", loc_id)
    let { token } = LoginService.getToken();
    let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.DIRECT_ORDERS(loc_id)}`,
        {
            method: "GET",
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
        }
    );
    response = await response.json();
    if (response) return response;
    throw new Error(`Could not get  order: ${response}`);
}
async function getSingleDirectOrder(order_id) {
    console.log("LOCATION: " + order_id);
    // console.log("Xxxx", loc_id)
    let { token } = LoginService.getToken();
    let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.SINGLE_DIRECT_ORDERS(
            order_id
        )}`,
        {
            method: "GET",
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
        }
    );
    response = await response.json();
    if (response) return response;
    throw new Error(`Could not get single order: ${response}`);
}

async function getSessionsList(params, searchdata = null) {
    let { token } = LoginService.getToken();
    let url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.SESSIONS_LIST(
        params.loc_id
    )}?page=${params?.page ? params.page : 0}`;

    if (searchdata != null && searchdata != "") {
        url += `&${searchdata}`;
    }

    let response = await fetch(url, {
        method: "GET",
        headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    });
    response = await response.json();
    if (response) return response;
    throw new Error(`Could not get live order: ${response}`);
}

async function getExportSessionList(params, searchdata = null) {
    let { token } = LoginService.getToken();
    let url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.SESSIONS_LIST_EXPORT(
        params.loc_id
    )}?page=${params?.page ? params.page : 0}`;

    if (searchdata != null && searchdata != "") {
        url += `&${searchdata}`;
    }

    let response = await fetch(url, {
        method: "GET",
        headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    });
    response = await response.json();
    if (response) return response;
    throw new Error(`Could not get live order: ${response}`);
}

async function getSessionsDetail(loc_id, id) {
    // console.log("Xxxx", loc_id)
    let { token } = LoginService.getToken();
    let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.SESSIONS_DETAIL(loc_id, id)}`,
        {
            method: "GET",
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
        }
    );
    response = await response.json();
    if (response) return response;
    throw new Error(`Could not get live order: ${response}`);
}

async function closeLocationSession(session_id) {
    let { token } = LoginService.getToken();
    let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.CLOSE_SESSION(session_id)}`,
        {
            method: "GET",
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
        }
    );
    response = await response.json();
    if (response) {
        return response;
    }
    throw new Error("Could not update");
}

async function deleteLocationSession(session_id) {
    console.log(session_id, "idididididididdi");
    let { token } = LoginService.getToken();
    let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DELETE_SESSION}`,
        {
            method: "POST",
            body: JSON.stringify({ session_id: session_id }),
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
        }
    );
    response = await response.json();
    if (response) {
        return response;
    }
    throw new Error("Could not update");
}

async function deleteOrderId(id) {
    let { token } = LoginService.getToken();
    let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.ORDER_ID(id)}`,
        {
            method: "Post",
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
        }
    );
    response = await response.json();
    if (response) {
        return response;
    }
    throw new Error("Could not update");
}
async function sendReceiptToCustomer(location_id, session_id, email) {
    let { token } = LoginService.getToken();
    let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.SENDRECEIPTEMAIL.default(
            location_id
        )}`,
        {
            method: "POST",
            body: JSON.stringify({ session_id: session_id, email: email }),
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
        }
    );
    if (response.ok) {
        response = await response.json();
        return response;
    }
    throw Error("Receipt not emailed to customer.");
}

export const createOrder = async ({
                                      user_id,
                                      table_id,
                                      location_id,
                                      session_date,
                                      start_time,
                                      end_time,
                                      session_id,
                                      order_time,
                                      order_date,
                                      order_type_id,
                                      order_variant,
                                      discount_id,
                                      total_discount,
                                      totalPrice,
                                      comment,
                                      qrcode,
                                      table_no,
                                      total_items,
                                      qrcodedata,
                                      promocode_id,
                                      hotel_id,
                                      room_id,
                                      payment_status_id,
                                      order_tax,
                                      session_owner,
                                      menu_items,
                                      order_from,
                                      pos_order
                                  }) => {
    let data = {
        user_id,
        table_id,
        location_id,
        session_date,
        start_time,
        end_time,
        session_id,
        order_time,
        hotel_id,
        room_id,
        order_date,
        order_type_id,
        order_variant,
        discount_id,
        total_discount,
        totalPrice,
        comment,
        qrcode,
        table_no,
        total_items,
        qrcodedata,
        promocode_id,
        payment_status_id,
        order_tax,
        session_owner,
        menu_items: menu_items,
        order_from: order_from,
        pos_order
    };

    let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.CREATE_SESSION_ORDER}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { ...CONSTANTS.REQUEST_HEADERS },
    });

    response = await response.json();
    if (response) return response;
    throw new Error(`Could not Create Order: ${response}`);
};

async function getPOSOrdersHistoryService () {
    const {token} = LoginService.getToken();
    let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.GET_POS_ORDERS}`,
        {
            method: "GET",
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token }
        })
    response = await response.json();
    if (response) return response;
    throw new Error(`Could not get pos orders: ${response}`);
}

async function deletePOSOrder(order_id) {
    const {token} = LoginService.getToken();
    try {
        const response = await axios.post(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.CANCEL_POS_ORDER(order_id)}`, {},{
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token }
        });

        if (!response || !response.data) {
            return {error: true}
        } else {
            return {error: false}
        }
    } catch (err) {
        console.log("Delete Order", err);
        return {error: true}
    }
}

async function modifyOrder(order_id, orderData) {
    const {token} = LoginService.getToken();
    try {
        const response = await axios.post(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.MODIFY_ORDER(order_id)}`, orderData, {
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token }
        });

        if (!response || !response.data) {
            return {error: true}
        } else {
            return {error: false}
        }
    } catch (err) {
        console.log("Modify Order", err);
        return {error: true}
    }
}