import CONSTANTS from "../../../constants";

export const addItemsToCart = (items) => ({
  type: CONSTANTS.ACTIONS.CART.ADD_ITEMS_TO_CART,
  payload: items,
});

export const getCartTotal = (totals) => ({
  type: CONSTANTS.ACTIONS.CART.GET_CART_TOTAL,
  payload: totals,
});
export const getAllAmounts = (totals) => ({
  type: CONSTANTS.ACTIONS.CART.GET_ALL_AMOUNTS,
  payload: totals,
});

export const removeItem = (items) => ({
  type: CONSTANTS.ACTIONS.CART.REMOVE_ITEM,
  payload: items,
});

export const clearCart = () => ({
  type: CONSTANTS.ACTIONS.CART.CLEAR_CART,
});

export const updateItemsInCart = (items) => ({
  type: CONSTANTS.ACTIONS.CART.UPDATE_ITEMS_IN_CART,
  payload: items,
})