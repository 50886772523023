import React from 'react';
import {finalOrderModalStyles, itemModal} from "../../../../../assets/posStyles";
import {Divider, IconButton, Modal, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FinalPaymentModalSmallScreen from "./finalPaymentModalSmallScreen";
import FinalPaymentModalLargeScreen from "./finalPaymentModalLargeScreen";

const FinalPaymentModal = ({openModal, setOpenModal, order, setSnackbarParams}) => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('md'));

    return(
        <>
            <Modal onClose={()=> setOpenModal(false)} open={openModal} sx={itemModal.modalStyle}>
                <Stack sx={finalOrderModalStyles.mainStack}>
                    <Stack direction='row' sx={{padding: '0.5rem 1rem', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Typography variant='h6' sx={{fontSize: '1.25rem', fontWeight: '600'}}>Finalize Sale</Typography>
                        <IconButton size='small' onClick={()=> setOpenModal(false)}>
                            <CloseIcon sx={{height: '20px', width: '20px', color: 'black'}}/>
                        </IconButton>
                    </Stack>
                    <Divider />
                    {sm ? <FinalPaymentModalSmallScreen order={order}/> : <FinalPaymentModalLargeScreen order={order} setOpenModal={setOpenModal} setSnackbarParams={setSnackbarParams}/>}
                </Stack>
            </Modal>
        </>
    )
}

export default FinalPaymentModal;