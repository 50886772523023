import React, {useEffect, useState} from 'react';
import {Stack, useMediaQuery, useTheme} from "@mui/material";
import LeftSection from "./leftSection";
import MiddleSection from "./middleSection";
import RightSection from "./rightSection";
import OpeningBalanceModal from "../../components/mainComponents/openingBalanceModal";
import {checkRegister} from "../../../../services/RegisterService";
import {useDispatch} from "react-redux";
import {setRegisterData} from "../../../../redux/actions/pos/register";

const MainScreen = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down("xl"));
    const sm = useMediaQuery(theme.breakpoints.down("md"));
    const [openModal, setOpenModal] = useState(false);

    const checkRegisterStatus = async () => {
        const response = await checkRegister();
        if (response.error) {
            setOpenModal(true);
        } else {
            await dispatch(setRegisterData(response.data.data[0]));
            setOpenModal(false);
        }
    }

    useEffect(() => {
        checkRegisterStatus()
    }, []);

    return(
        <>
            <Stack direction='row' sx={{padding: '0.5rem', flexGrow: 1, columnGap: '0.5rem'}}>
                <Stack direction='row' sx={{width: {xs: '100%', md: '50%'}, columnGap: '0.5rem'}}>
                    {!md && <LeftSection/>}
                    <MiddleSection />
                </Stack>
                {!sm && <RightSection/>}
            </Stack>
            <OpeningBalanceModal setOpenModal={setOpenModal} openModal={openModal}/>
        </>
    )
}

export default MainScreen;