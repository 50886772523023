import MuiAlert from "@mui/material/Alert";
import React from "react";
import {Snackbar} from "@mui/material";

const SnackBar = ({snackbarParams, setSnackbarParam}) => {

    return(
        <Snackbar open={snackbarParams.open} autoHideDuration={2500} onClose={()=> setSnackbarParam({
            open: false,
            message: "",
            severity: "",
        })}>
            <MuiAlert elevation={6} variant="filled" onClose={()=> setSnackbarParam({open: false, message: "", severity: ""})} severity={snackbarParams.severity}>
                {snackbarParams.message}
            </MuiAlert>
        </Snackbar>
    )
}

export default SnackBar