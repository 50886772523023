import CONSTANTS from "../constants";
import LoginService from "./LoginService";
import { customFetch as fetch } from "../helpers/FetchHelper";
import axios from "axios";

export default {
  getAllPayment,
  savePayment,
  getPaymentId,
  updatePayment,
  deletePayment,
  orderPayment
};

async function getAllPayment(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.PAYMENT_METHODS.DEFAULT(
          locationId
      )}`,
      {
        method: "GET",
        headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
      }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch payment methods");
}

async function savePayment(locationId, payment) {
  console.log("locationId", locationId);
  let { token } = LoginService.getToken();
  let response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.PAYMENT_METHODS.SAVE_RAYMENT(
          locationId
      )}`,
      {
        method: "POST",
        body: JSON.stringify(payment),
        headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
      }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not save payment");
}

async function getPaymentId(locationId) {
  console.log("locationId", locationId);
  let { token } = LoginService.getToken();
  let response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.PAYMENT_METHODS.GET_PAYMENT_ID(
          locationId
      )}`,
      {
        method: "GET",
        headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
      }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not save payment");
}

async function updatePayment(locationId, payment) {
  console.log("locationId", locationId);
  let { token } = LoginService.getToken();
  let response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.PAYMENT_METHODS.UPDATE_PAYMENT(
          locationId
      )}`,
      {
        method: "PUT",
        body: JSON.stringify(payment),
        headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
      }
  );
  response = await response.json();
  console.log(response, "suess");
  if (response) return response;
  throw new Error("Could not update payment");
}

async function deletePayment(paymentId, locationId) {
  console.log("locationId", paymentId);
  let { token } = LoginService.getToken();
  let response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.PAYMENT_METHODS.DELETE(
          paymentId
      )}`,
      {
        method: "POST",
        body: JSON.stringify(locationId),
        headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
      }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update payment");
}

async function orderPayment (order_id, data){
  let { token } = LoginService.getToken();
  try {
    const response = await axios.post(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.FINALIZE_ORDER_PAYMENT(order_id)}`, data,
        {headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token }
        });

    if (!response || !response.data) {
      return {error: true}
    } else {
      return {data: response.data, error: false}
    }
  } catch (err) {
    console.log("Order Payment", err);
    return {error: true}
  }
}