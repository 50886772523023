import React from 'react';
import {Stack} from "@mui/material";
import LeftSideLeftIcons from "../../components/navbar/largeScreen/leftSideLeftIcons";
import LeftSideRightIcons from "../../components/navbar/largeScreen/leftSideRightIcons";

const LargeScreenNavbarLeftSide = () => {
    return(
        <Stack direction='row' sx={{width: '50%', justifyContent: 'space-between', alignItems: 'center'}}>
            <LeftSideLeftIcons />
            <LeftSideRightIcons />
        </Stack>
    )
}

export default LargeScreenNavbarLeftSide;