
import CONSTANTS from "../../../../constants";
import TableService from "../../../../services/TableService";

export function setLocationTables(locationId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.POS.ALL_TABLES,
        tables: [],
        loading: true,
      });
      let tables = await TableService.getAllSessionTables(locationId);
      dispatch({
        type: CONSTANTS.ACTIONS.POS.ALL_TABLES,
        tables,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.POS.ALL_TABLES,
        tables: [],
        loading: false,
      });
    }
  };
}

export function setSelectedTableRedux(table) {
  return (dispatch) => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.POS.SELECTED_TABLE, table: table});
    } catch (err) {
      console.log(err);
    }
  }
}