import React from 'react';
import CartComponent
    from "../../../components/mainScreen/middleSectionComponents/middleSectionComponents/cartComponent";
import OrderAmountComponent
    from "../../../components/mainScreen/middleSectionComponents/middleSectionComponents/orderAmountComponent";
import ModifyCartComponent
    from "../../../components/mainScreen/middleSectionComponents/middleSectionComponents/modifyCartComponent";
import {useSelector} from "react-redux";

const MiddleSectionComponents = () => {
    const isModifyOrder = useSelector(state => state.pos.isModifyOrder);

    return(
        <>
            {isModifyOrder ? <ModifyCartComponent /> : <CartComponent/>}
            <OrderAmountComponent />
        </>
    )
}

export default MiddleSectionComponents;