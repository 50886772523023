import React, {useEffect, useState} from 'react';
import {
    Button,
    Divider,
    Grid,
    IconButton,
    Modal,
    Stack, TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {itemModal, orderDetailModalStyles} from "../../../../assets/posStyles";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptIcon from '@mui/icons-material/Receipt';
import {Table} from "reactstrap";
import numeral from "numeral";
import {grey} from "@mui/material/colors";
import {useSelector} from "react-redux";

const orderTopInfo = [
    {
        label: "Order Type",
    },

    {
        label: "Order Number"
    },

    {
        label: "Waiter"
    },

    {
        label: "Customer"
    },

    {
        label: "Table"
    }
]

const tableHeaders = [
    "Item", "Price", "Qty", "Discount", "Total"
]

const bottomItems = [
    {
        label: "Total Items"
    },

    {
        label: "Sub Total"
    },

    {
        label: "Total Discount"
    },

    {
        label: "Tax"
    },

    {
        label: "Charge"
    },

    // {
    //     label: "Tips"
    // }
]

const OrderDetailModal = ({openModal, setOpenModal, order, setOpenFinalizeSale}) => {
    const locationData = useSelector(state => state.pos.locationData);
    const [values, setValues] = useState({
        discount: 0,
        tax: 0,
        subTotal: 0
    })

    useEffect(() => {
        if (order && order.OrderTotals) {
            const data = {
                discount: 0,
                tax: 0,
                subTotal: 0
            }
            for (let i = 0; i < order.OrderTotals.length; i++) {
                if (order.OrderTotals[i].code === "discount") {
                    data.discount = order.OrderTotals[i].value;
                }

                if (order.OrderTotals[i].code === "tax") {
                    data.tax = order.OrderTotals[i].value;
                }

                if (order.OrderTotals[i].code === "cart_total") {
                    data.subTotal = order.OrderTotals[i].value;
                }
            }
            setValues(data);
        }
    }, [order]);

    const formatPrice = (price) => {
        if (price >= 1000) {
            const formattedValue = numeral(price).format("0.0a");
            return formattedValue;
        } else {
            return numeral(price).format("0.00");
        }
    };

    const calculateTotalPrice = (menuPrice, taxPercent, itemCount) => {
        const itemPrice = menuPrice || 0;
        const tax = taxPercent || 0;

        const totalPrice =
            tax > 0
                ? (itemPrice + (itemPrice * tax) / 100) * itemCount
                : itemPrice * itemCount;

        return formatPrice(totalPrice);
    };

    const calculateVariantPrice = (optionPrice, taxPercent, itemCount) => {
        const itemPrice = optionPrice || 0;
        const tax = taxPercent || 0;
        const variantPrice =
            tax > 0
                ? itemPrice * itemCount * (tax / 100) + itemPrice * itemCount
                : itemPrice * itemCount;

        return variantPrice;
    };

    return(
        <>
            {order && (
                <Modal open={openModal} onClose={()=> setOpenModal(false)} sx={itemModal.modalStyle}>
                    <Stack sx={orderDetailModalStyles.mainStack}>
                        <Stack direction='row' sx={{padding: '0.5rem 1rem', alignItems: 'center', justifyContent: 'space-between'}}>
                            <Typography variant='h6' sx={{fontSize: '1.25rem', fontWeight: '600'}}>Order Details</Typography>
                            <IconButton size='small' onClick={()=> setOpenModal(false)}>
                                <CloseIcon sx={{height: '20px', width: '20px', color: 'black'}}/>
                            </IconButton>
                        </Stack>
                        <Divider />
                        <Stack sx={{padding: '0.5rem 1rem', rowGap: '0.5rem'}}>
                            <Grid container spacing={1}>
                                {orderTopInfo.map((item, index) => (
                                    <Grid key={index} item xs={12} sm={6} md={4} sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography variant='body2' sx={{fontWeight: '600', marginRight: '0.25rem'}}>{item.label}:</Typography>
                                        <Typography variant='body2' sx={{textTransform: 'capitalize'}}>
                                            {index === 0 ?
                                                order.order_type ? order.order_type : 'Dine In' : index === 1 ?
                                                    order.order_id : index === 2 ? 'None' : index === 3 ? "None" : index === 4 ? (order.table_name ? order.table_name : "None") : ""
                                            }
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                            <Stack sx={{border: `1px solid ${grey[200]}`, borderRadius: '0.25rem', overflow: 'hidden'}}>
                                <Table>
                                    <TableHead sx={{backgroundColor: grey[100]}}>
                                        <TableRow>
                                            {tableHeaders.map((head, index)=> (
                                                <TableCell sx={{padding: '0.5rem !important', fontWeight: '600'}} key={index}>{head}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {order.OrderMenus.map((menu, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>{menu.name}</TableCell>
                                                <TableCell sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>{locationData.Currency.currency_symbol} {calculateTotalPrice(menu.price, menu.menu_tax_percentage, 1)}</TableCell>
                                                <TableCell sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>{menu.quantity}</TableCell>
                                                <TableCell sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>{locationData.Currency.currency_symbol} {0.00}</TableCell>
                                                <TableCell sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>{locationData.Currency.currency_symbol} {calculateTotalPrice(menu.price, menu.menu_tax_percentage, menu.quantity)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Stack>
                            <Grid container spacing={1}>
                                {bottomItems.map((item, index) => (
                                    <Grid key={index} item xs={12} sm={6} md={4} sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography variant='body2' sx={{fontWeight: '600', marginRight: '0.25rem'}}>{item.label}:</Typography>
                                        <Typography variant='body2'>
                                            {index === 0 ?
                                                order.total_items :
                                                index === 1 ? locationData.Currency.currency_symbol + ' ' + values.subTotal :
                                                    index === 2 ? locationData.Currency.currency_symbol + ' ' + values.discount :
                                                        index === 3 ? locationData.Currency.currency_symbol + ' ' + values.tax :
                                                            index === 4 ? locationData.Currency.currency_symbol + ' ' + "0" : ""
                                            }
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'center', backgroundColor: grey[200], borderRadius: '4px', padding: '0.25rem 1rem'}}>
                                <Typography variant='h6'>Total Payable : </Typography>
                                <Typography variant='h6'>{locationData.Currency.currency_symbol} {order.order_total}</Typography>
                            </Stack>
                        </Stack>
                        <Divider />
                        <Stack direction='row' sx={{alignItems: 'center', columnGap: '1rem', padding: '0.5rem 1rem'}}>
                            <Button sx={orderDetailModalStyles.saveButton} startIcon={<ReceiptIcon />} variant='contained' onClick={()=> {
                                setOpenFinalizeSale(true);
                                setOpenModal(false);
                            }}>Create Invoice & Close</Button>
                            <Button sx={orderDetailModalStyles.closeButton} variant='contained' onClick={()=> setOpenModal(false)}>Close</Button>
                        </Stack>
                    </Stack>
                </Modal>
            )}
        </>
    )
}

export default OrderDetailModal;