import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import customizer from "./customizer";
import locations from "./locations/";
import orders from "./orders/";
import configurations from "./configurations";
import analytics from "./analytics";
import users from "./users";
import categories from "./categories";
import options from "./options";
import currency from "./currency";
import tables from "./tables";
import floors from "./floors";
import staff from "./staff";
import roles from "./roles";
import menu from "./menus";
import notifications from "./notifications";
import surveyquestions from "./survey_questions";
import surveyresponse from "./survey_response";
import productpricing from "./productpricing";
import packageproductcategory from "./packageproductcategory";
import payment from "./paymentMethods"
import pricing from "./pricing";
import roomReducer from "./rooms";
import reviewsReducer from "./reviews";
import cart from "./cart";
import premium_services from "./premiumservices";
import reservationsReducers from "./reservations";
import pos from "./pos";

const rootReducer = combineReducers({
  customizer,
  orders,
  configurations,
  locations,
  users,
  analytics,
  categories,
  options,
  currency,
  tables,
  staff,
  roles,
  cart,
  form,
  roomReducer,
  reviewsReducer,
  menu,
  notifications,
  surveyquestions,
  surveyresponse,
  pricing,
  productpricing,
  floors,
  packageproductcategory,
  payment,
  premium_services,
  reservationsReducers,
  pos
});

export default rootReducer;
