
import CONSTANTS from "../../../../constants";

export function setPOSLocationData(location) {
    return (dispatch) => {
        dispatch({type: CONSTANTS.ACTIONS.POS.LOCATION_DATA, data: location});
    }
}

export function setPOSUserLocationData (userLocation) {
    return (dispatch) => {
        dispatch({type: CONSTANTS.ACTIONS.POS.USER_LOCATION_DATE, data: userLocation});
    }
}