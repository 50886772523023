import React, {useEffect, useState} from 'react';
import GoogleMapReact from "google-map-react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {Box, Button} from "@mui/material";
import {getGenericMapsLocationData} from "../../utils/locationAutoCompleteHelper";
import {setPOSUserLocationData} from "../../../../redux/actions/pos/location";
import {useDispatch, useSelector} from "react-redux";
import SnackBar from "./snackBar";
import {green} from "@mui/material/colors";

const AnyReactComponent = () => (
    <LocationOnIcon size={40} style={{ color: "red" }} />
);

const GenericMaps = ({updateCustomer, latitude, longitude}) => {
    const dispatch = useDispatch();
    const userLocationData = useSelector(state => state.pos.userLocationData);

    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        severity: '',
        message: '',
    })
    const [center, setCenter] = useState({
        lat: 50,
        lng: 50,
    });


    useEffect(() => {
        setCenter({
            lat: userLocationData.lat ? userLocationData.lat: 50,
            lng: userLocationData.long ? userLocationData.long: 50
        })
    }, [userLocationData]);

    const handleMapChange = (e) => {
        setCenter({ lat: e.center.lat(), lng: e.center.lng() });
    };

    const handleSaveLocation = async () => {
        const userLocationData = await getGenericMapsLocationData(center.lat, center.lng);
        if (userLocationData.error) {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: 'Error Setting Location'
            })
        } else {
            await dispatch(setPOSUserLocationData(userLocationData.data));
            setSnackbarParams({
                open: true,
                severity: 'success',
                message: 'Location Pined Successfully'
            })
        }
    }

    return(
        <>
            <Box sx={{height: '250px', width: '100%', borderRadius: '0.25rem', overflow: 'hidden', position: 'relative'}}>
                <GoogleMapReact
                    defaultCenter={center}
                    bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_KEY}}
                    defaultZoom={4}
                    onDrag={(e)=> handleMapChange(e)}
                >
                    {center && (
                        <AnyReactComponent lat={center.lat} lng={center.lng}/>
                    )}
                </GoogleMapReact>
                <Button sx={{position: 'absolute', bottom: '0.5rem', left: '0.5rem', backgroundColor: green[600], '&:hover': {backgroundColor: green[800]}}} variant='contained' onClick={()=> handleSaveLocation()}>Set Pin</Button>
            </Box>
            <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
        </>
    )
}

export default GenericMaps;