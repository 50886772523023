import React, {useState} from "react";
import {Fullscreen, FullscreenExit, Menu} from "@mui/icons-material";
import {IconButton, Stack, Tooltip} from "@mui/material";

const largeNavbarRightIcons = [
    {
        onIcon: <Fullscreen sx={{color: '#186218'}} />,
        exitIcon: <FullscreenExit sx={{color: '#186218'}} />,
        onHelperText: "Enter Fullscreen",
        offHelperText: "Exit Fullscreen"
    },

    {
        icon: <Menu sx={{color: '#186218'}} />,
        helperText: "Main Menu"
    }
];

const LeftSideRightIcons = () => {
    const [fullscreen, setFullScreen] = useState(false);

    const handleFullScreen = async () => {
        try {
            if (!fullscreen) {
                await document.documentElement.requestFullscreen();
            } else {
                await document.exitFullscreen();
            }
            setFullScreen(!fullscreen);
        } catch (err) {
            console.error("Error attempting to toggle fullscreen:", err);
        }
    };

    return (
        <Stack direction='row'>
            {
                largeNavbarRightIcons.map((item, index) => (
                    index === 0 ? (
                        <Tooltip title={fullscreen ? item.offHelperText : item.onHelperText} key={index}>
                            <IconButton onClick={handleFullScreen}>
                                {fullscreen ? item.exitIcon : item.onIcon}
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title={item.helperText} key={index}>
                            <IconButton>
                                {item.icon}
                            </IconButton>
                        </Tooltip>
                    )
                ))
            }
        </Stack>
    );
};

export default LeftSideRightIcons;
