import React from 'react'
import {Stack, useMediaQuery, useTheme} from "@mui/material";
import CategorySelectionSection from "../../../components/mainScreen/rightSectionComponents/categorySelectionSection";
import MenuItemSelectionComponent
    from "../../../components/mainScreen/rightSectionComponents/menuItemSelectionComponent";


const MainSectionComponents = ({locationData}) => {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down("xl"))

    return (
        <Stack direction='row' sx={{columnGap: '0.5rem'}}>
            {!md && <CategorySelectionSection />}
            <MenuItemSelectionComponent locationData={locationData} />
        </Stack>

    )
}

export default MainSectionComponents;