import CONSTANTS from "../../../../constants";

const userLocationInitialState = {
    address: '',
    latitude: '',
    longitude: '',
}

export function locationData(state = null, action) {
    if (action.type === CONSTANTS.ACTIONS.POS.LOCATION_DATA) {
        return { ...action.data };
    }

    return state
}

export function userLocationData(state = userLocationInitialState, action) {
    if (action.type === CONSTANTS.ACTIONS.POS.USER_LOCATION_DATE) {
        return { ...action.data };
    }

    return state
}