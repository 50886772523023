import {getLocationData} from "../../../services/LocationService";

export const handleAutocompleteChange = (event, newValue, options, setValue, setOptions, dispatch, setSnackbarParams, restaurant) => {
    console.log(options);
    // setOptions(newValue ? [newValue, ...options] : options);
    // setValue(newValue);
    if (newValue) {
        const request = {
            placeId: newValue.place_id,
            fields: ['geometry']
        };
        const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
        placesService.getDetails(request, async (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const {lat, lng} = place.geometry.location;
                try {
                    const response = await getLocationData(lat(), lng())
                    const userLocation = {
                        locationName: newValue.description,
                        address: response.data.results[0].formatted_address,
                        lat: lat(),
                        long: lng(),
                        country: '',
                        state: '',
                        city: '',
                        streetName: '',
                        streetNumber: '',
                        postal_code: '',
                        place_id: response.data.results[0].place_id
                    }

                    response.data.results[0].address_components.map((item) => {
                        if (item.types.includes('country')) {
                            userLocation.country = item.long_name
                        }
                        if (item.types.includes('administrative_area_level_1')) {
                            userLocation.state = item.long_name
                        }
                        if (item.types.includes('locality')) {
                            userLocation.city = item.long_name
                        }
                        if (item.types.includes('route')) {
                            userLocation.streetName = item.long_name
                        }
                        if (item.types.includes('street_number')) {
                            userLocation.streetNumber = item.long_name
                        }
                        if (item.types.includes('postal_code')) {
                            userLocation.postal_code = item.long_name
                        }
                    })

                    console.log(userLocation)
                } catch (err) {
                    console.error(err)
                }
            } else {
                console.error('Error fetching place details:', status);
            }
        });
    }
}

export const getGenericMapsLocationData = async (lat, lng) => {
    try {
        const response = await getLocationData(lat, lng)
        console.log("Response", response);
        const userLocation = {
            address: response.data.results[0].formatted_address,
            lat: lat,
            long: lng,
            country: '',
            state: '',
            city: '',
            streetName: '',
            streetNumber: '',
            postal_code: '',
            place_id: response.data.results[0].place_id
        }

        response.data.results[0].address_components.map((item) => {
            if (item.types.includes('country')) {
                userLocation.country = item.long_name
            }
            if (item.types.includes('administrative_area_level_1')) {
                userLocation.state = item.long_name
            }
            if (item.types.includes('locality')) {
                userLocation.city = item.long_name
            }
            if (item.types.includes('route')) {
                userLocation.streetName = item.long_name
            }
            if (item.types.includes('street_number')) {
                userLocation.streetNumber = item.long_name
            }
            if (item.types.includes('postal_code')) {
                userLocation.postal_code = item.long_name
            }
        })

        return {error: false, data: userLocation};
    } catch (err) {
        console.error(err)
        return {error: true}
    }
}