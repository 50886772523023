import React from 'react';
import {Stack} from "@mui/material";
import TopSectionComponents from "./middleSections/topSectionComponents";
import MiddleSectionComponents from "./middleSections/middleSectionComponents";
import BottomSectionComponents from "./middleSections/bottomSectionComponents";

const MiddleSection = () => {
    return(
        <Stack sx={{backgroundColor: 'white', height: '100%', borderRadius: '0.25rem', flexGrow: 1, padding: '0.5rem', rowGap: '0.5rem'}}>
            <TopSectionComponents />
            <MiddleSectionComponents />
            <BottomSectionComponents />
        </Stack>
    )
}

export default MiddleSection;