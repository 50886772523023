import { combineReducers } from 'redux'
import {dummyItem, allModifiers, allDishTags, allAllergicItems, menuData, selectedCategory} from "./MenuReducer";


const menuReducer = combineReducers({
  dummyItem,
  allModifiers,
  allDishTags,
  allAllergicItems,
  menuData,
});

export default menuReducer;
