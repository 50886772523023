import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';
import loginService from "./LoginService";
import axios from "axios";

export default {
  getAllUsers,
  updateBanStatus,
  getAllReports,
  deleteReports,
  getPOSCustomers,
  addPOSUser,
  updatePOSCustomer
};


async function getAllUsers() {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.DEFAULT}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not fetch users');
}

async function updateBanStatus(users) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.UPDATE_BAN}`, {
    method: 'POST',
    body: JSON.stringify(users),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not update bann users');
}

async function getAllReports() {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.ALL_REPORTS}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not fetch reports');
}

async function deleteReports(reports) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.DEL_REPORT}`, {
    method: 'POST',
    body: JSON.stringify(reports),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not delete report');
}

async function addPOSUser (customerData) {
  const {token} = loginService.getToken();
  try {
    const response = await axios.post(`${CONSTANTS.API_URL}${CONSTANTS.URLS.USERS.NEW_POS_USER}`, {...customerData}, {
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token }
    })

    if (!response || !response.data) {
      return {error: true, message: "Failed to a New Customer"}
    } else {
      return {error: false, message: "Customer Added Successfully"};
    }
  } catch (err) {
    console.log(err)
    return {error: true, message: "Failed to Add New Customer"}
  }
}

async function getPOSCustomers (searchQuery){
  const {token} = loginService.getToken();
  try {
    const response = await axios.get(`${CONSTANTS.API_URL}${CONSTANTS.URLS.USERS.GET_POS_USERS(searchQuery)}`, {
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token }
    })

    if (response && response.data) {
      return {error: false, data: response.data};
    } else {
      return {error: true, data: []};
    }
  } catch (err) {
    console.log(err);
    return {error: true, data: []}
  }
}

async function updatePOSCustomer (customer_id, data)  {
  console.log("Customer Data", data)
  const {token} = loginService.getToken();
  try {
    const response = await axios.put(`${CONSTANTS.API_URL}${CONSTANTS.URLS.USERS.EDIT_POS_USER(customer_id)}`, {...data}, {
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token }
    })

    if (!response || !response.data) {
      return {error: true, message: "Failed to a New Customer"}
    } else {
      return {error: false, message: "Customer Added Successfully"};
    }
  } catch (err) {
    console.log(err)
    return {error: true, message: "Failed to Add New Customer"}
  }
}