import React, {useEffect, useRef} from 'react';
import {useReactToPrint} from "react-to-print";
import {useHistory} from "react-router-dom";
import PrintableComponent from "./printableComponent";

const BillPrint = () => {
    const history = useHistory();
    const printRef = useRef();
    const ordersData = JSON.parse(localStorage.getItem("orderData"));

    const checkDataAndPrint = () => {
        const orderData = JSON.parse(localStorage.getItem("orderData"));
        console.log(orderData);

        if (!orderData || orderData.length === 0 || !orderData[0] || !orderData[0].order_id) {
            history.push("/pos");
        } else if (!orderData || orderData.length === 0 || !orderData[1] || !orderData[1].loc_id) {
            history.push("/pos");
        }

        printBill();
    }

    const printBill = useReactToPrint({
        content: () => printRef.current
    })

    useEffect(() => {
        checkDataAndPrint();
    }, []);

    return (
        ordersData && ordersData[0] && ordersData[1] &&
        <PrintableComponent ref={printRef} locationData={ordersData[1]} orderData={ordersData[0]} />
    );
};

export default BillPrint;