import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import { store } from "./redux/storeConfig/store";
import { Layout } from "./helpers/context/Layout";
import PrivateRoute from "./components/shared/PrivateRoute";
import PublicRoute from "./components/shared/PublicRoute";
import Login from "./components/sessions/Login";
import ForgetPassword from "./components/sessions/ForgetPassword";
import ResetPassword from "./components/sessions/ResetPassword";
import Logout from "./components/sessions/Logout";
import OrderSessions from "./components/ordersessions/OrderSessions";
import OrderSessionsDetail from "./components/ordersessions/OrderSessionsDetail";
import Locations from "./components/locations/Locations";
import Hotels from "./components/hotels/Hotels";
import AllLocations from "./components/locations/AllLocations";
import AllPackages from "./components/packages/Packages";
import ShowPackages from "./components/packages/ShowPackages";
import Location from "./components/locations/Location";
import NewLocation from "./components/locations/NewLocation";
import SelectLocation from "./components/shared/SelectLocation";
import OverallStats from "./components/analytics/OverallStats";
import ItemsStats from "./components/analytics/ItemsList";
import LocationStats from "./components/analytics/LocationStats";
import DishTags from "./components/dishtags/DishTags";
import DishTagForm from "./components/dishtags/DishTagForm";
import AllergicItems from "./components/allergicitems/AllergicItems";
import AllergicItemsForm from "./components/allergicitems/AllergicItemsForm";
import Branding from "./components/branding/index";
import NotificationList from "./components/notificationlist";
import Questionnaire from "./components/questionnaire";
import Rooms from "./components/rooms/index";
import SurveyResponse from "./components/survey_response";
import packageSession from "./components/packagesession/packageSession";
import pricingsession from "./components/pricingsession/pricingSession";
import Faq from "./components/faq/faq";
import Wiki from "./components/wiki/wiki";
import Contact from "./components/contactus/Contact";
import Training from "./components/training/Training";
import Emergency from "./components/emergency/Emergency";
import Previews from "./components/previews/Previews";
import License from "./components/License/License";
import Payment from "./components/payment/Payment";
import Checkout from "./components/packages/Checkout";
import * as serviceWorker from "./serviceWorker";
import CONSTANTS from "./constants";

import "./components/@vuexy/rippleButton/RippleButton";
import "react-perfect-scrollbar/dist/css/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import "./index.scss";

import Menu from "./components/menus/Menu";
import ScrollToTop from "./components/shared/ScrollToTop";

import TermsConditions from "./components/terms/TermsConditions";
import CheckoutMessage from "./components/checkoutmessage/CheckoutMessage";
import AppUsers from "./components/appdata/users/AppUsers";
import Currency from "./components/currencies/Currency";
import CurrencyForm from "./components/currencies/CurrencyForm";
import AddIcon from "./components/addIcons/addIcon";
import Tables from "./components/tables/Tables";
import SessionTables from "./components/tables/SessionTables";
import Banners from "./components/banners/";
import TablesForm from "./components/tables/TablesForm";
import Staff from "./components/staff/Staff";
import CustomerList from "./components/staff/CustomerList";
import StaffForm from "./components/staff/StaffForm";
import Roles from "./components/roles/Roles";
import RolesForm from "./components/roles/RolesForm";
import SendPopupsOnTabs from "./components/sendpopups/SendPopupsOnTabs";
import AssignRole from "./components/staff/rolesassign/RoleAssign";
import AssignRoleForm from "./components/staff/rolesassign/RoleAssignForm";
import Pdf from "./components/upload_pdf/uploadpdf";
import PackageSessionForm from "./components/packagesession/PackageSessionForm";
import EditSessionForm from "./components/packagesession/EditSessionForm";
import PricingSessionForm from "./components/pricingsession/PricingSessionForm";

import Modifiers from "./components/modifiers/Modifiers";
import Dashboard from "./components/dashboard/Dashboard";
import GoogleTranslateComponent from "./Translate";
import Kitchen from "./components/applications/Kitchen";
import Eater from "./components/applications/Eater";
import PointofSale from "./components/pos";

import Waiter from "./components/applications/Waiter";
import Website from "./components/applications/Website";
import EditPricingSessionForm from "./components/pricingsession/EditPricingSessionForm";
import PurchaseHistory from "./components/purchasehistory/purchasehistory";
import PurchaseHistoryDetail from "./components/purchasehistory/purchasehistorydetail";

import global_en from "./translations/en/global.json";
import global_fr from "./translations/fr/global.json";
import global_nl from "./translations/nl/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import CategoriesMobilePreview from "./components/menus/preview/CategoriesPreview";
import MenuMobilePreview from "./components/menus/preview/MenuMobilePreview";
import MenuOptionsPreview from "./components/menus/preview/MenuOptionsPreview";
import FloorsManagement from "./components/floors/FloorsManagement";
import FloorTablesManagement from "./components/floors/tables/tables";
// import TestDrag from './components/testdrag'

import MenuItemPage from "./components/shared/forms/MenuItemPage";
import AddPayment from "./components/payment/AddPayment";
import Paymentlist from "./components/payment/Paymentlist";
import Detailspurchasehistory from "./components/purchasehistory/Detailspurchasehistory";
import DirectSessions from "./components/directSessions/DirectSessions";
import DirectOrdersDetail from "./components/directSessions/DirectOrdersDetail";
import timeslots from "./components/timeslots/timeslots";
import LocationTimeSlots from "./components/timeslots/timeslots";
import SelectedTimeSlotsPage from "./components/timeslots/selectedTimeSlots";
import PosOrders from "./components/PosOrders/PosOrders";
import PosOrdersDetail from "./components/PosOrders/PosOrdersDetail";

import NewHotel from "./components/hotels/NewHotel";
import Hotel from "./components/hotels/Hotel";
import SelectHotel from "./components/shared/SelectHotel";
import HotelPage from "./components/hotels/HotelPage";
import Room from "./components/rooms/Room";
import ListRooms from "./components/rooms/ListRooms";
import EditForm from "./components/rooms/EditForm";
import SessionRooms from "./components/rooms/SessionRooms";
import Review from "./components/reviews/Review";
import UpcomingEvents from "./components/UpcomingEvents/UpcomingEvents";
import Premium_Services from "./components/premium_services/Premium_Services";
import AddNewPremiumService from "./components/premium_services/addNewPremiumService";
import UpdateNDeletePremiumService from "./components/premium_services/updateNDeletePremiumService";
import Reservations from "./components/reservations/Reservations";
import NewReservation from "./components/reservations/newReservation";
import UpdateNDeleteReservation from "./components/reservations/updateNDeleteReservation";
import Pos from "./components/pos2/pages/pos";
import './App.css'
import BillPrint from "./components/pos2/components/mainComponents/billPrint/billPrint";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    fr: {
      global: global_fr,
    },
    du: {
      global: global_nl,
    },
  },
});

ReactDOM.render(
    <Provider store={store}>
      {/* <GoogleTranslateComponent /> */}
      <I18nextProvider i18n={i18next}>
        <Layout>
          <BrowserRouter basename="/">
            <ScrollToTop />
            <div className="app-container">
              <Switch>
                <PublicRoute
                    fullLayout
                    exact
                    path={`${CONSTANTS.BASE_URL}login`}
                    component={Login}
                />{" "}
                <PublicRoute
                    fullLayout
                    exact
                    path={`${CONSTANTS.BASE_URL}forget_password`}
                    component={ForgetPassword}
                />{" "}
                <PublicRoute
                    fullLayout
                    exact
                    path={`${CONSTANTS.BASE_URL}reset_password`}
                    component={ResetPassword}
                />{" "}
                <PublicRoute
                    fullLayout
                    exact
                    path={`${CONSTANTS.BASE_URL}logout`}
                    component={Logout}
                    pageTitle={"Logout"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}`}
                    component={Dashboard}
                    pageTitle={"Dashboard"}
                />{" "}
                <PrivateRoute
                    poslayout={true}
                    exact
                    path={`${CONSTANTS.BASE_URL}pos`}
                    component={Pos}
                    pageTitle={"POS"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}sessions`}
                    component={OrderSessions}
                    pageTitle={"Order sessions"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}sessions/directPOS`}
                    component={PosOrders}
                    pageTitle={"Direct Order sessions"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}Posorders/:id`}
                    component={PosOrdersDetail}
                    pageTitle={"Direct Orders Detail"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}sessions/direct`}
                    component={DirectSessions}
                    pageTitle={"Direct Order sessions"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}sessions/:id`}
                    component={OrderSessionsDetail}
                    pageTitle={"Session Detail"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}orders/:id`}
                    component={DirectOrdersDetail}
                    pageTitle={"Direct Orders Detail"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}select_location`}
                    component={SelectLocation}
                    pageTitle={"Select Restaurant"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}select_hotel`}
                    component={SelectHotel}
                    pageTitle={"Select Hotel"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}appdata/users`}
                    component={AppUsers}
                    pageTitle={"App Users"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}currencies`}
                    component={Currency}
                    pageTitle={"Currencies"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}currencies/new`}
                    component={CurrencyForm}
                    pageTitle={"Currency form"}
                    goBackURL={"currencies"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}currencies/edit/:currency_id`}
                    component={CurrencyForm}
                    pageTitle={"Currency form"}
                    goBackURL={"currencies"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}icons`}
                    component={AddIcon}
                    pageTitle={"Add Icon"}
                    goBackURL={"currencies"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}tables`}
                    component={Tables}
                    pageTitle={"Tables"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}tables/session`}
                    component={SessionTables}
                    pageTitle={"Session Tables"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}rooms/session`}
                    component={SessionRooms}
                    pageTitle={"Session Rooms"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}banners`}
                    component={Banners}
                    pageTitle={"Banners"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}tables/new`}
                    component={TablesForm}
                    goBackURL={"tables"}
                    pageTitle={"Add New Tables"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}tables/edit/:table_id`}
                    component={TablesForm}
                    goBackURL={"tables"}
                    pageTitle={"Edit Tables"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}staff`}
                    component={Staff}
                    pageTitle={"Manager Accounts"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}staff/assign/:id`}
                    component={AssignRole}
                    goBackURL={"staff"}
                    pageTitle={"Assign Role"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}staff/assign/new/:id`}
                    component={AssignRoleForm}
                    goBackURL={"staff"}
                    pageTitle={"Assign Role"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}staff/new`}
                    component={StaffForm}
                    goBackURL={"staff"}
                    pageTitle={"Staff New"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}packages/new`}
                    component={PackageSessionForm}
                    goBackURL={"packages"}
                    pageTitle={"Packages New"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}pricing/new`}
                    component={PricingSessionForm}
                    goBackURL={"prices"}
                    pageTitle={"Prices New"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}purchase-history`}
                    component={PurchaseHistory}
                    goBackURL={"dashboard"}
                    pageTitle={"Purchase History"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}purchase-history-detail`}
                    component={PurchaseHistoryDetail}
                    goBackURL={"purchase-history"}
                    pageTitle={"Purchase History Detail"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}purchase-history-list`}
                    component={Detailspurchasehistory}
                    goBackURL={"purchase-history"}
                    pageTitle={"Purchase History Detail"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}staff/edit/:id`}
                    component={StaffForm}
                    pageTitle={"Edit Staff"}
                    goBackURL={"staff"}
                    withConfirmation={true}
                />{" "}
                {/* <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}packages/edit/:packageId`}
              component={EditSessionForm}
              pageTitle={'Edit Package'}
              goBackURL={'packages'}
              withConfirmation={true}
            />{' '} */}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}packages/edit/:packageId`}
                    component={(routeProps) => {
                      const packageId = routeProps.match.params.packageId;
                      const locationState = routeProps.location.state;

                      return (
                          <EditSessionForm packageId={packageId} {...locationState} />
                      );
                    }}
                    pageTitle={"Edit Package"}
                    goBackURL={"packages"}
                    withConfirmation={true}
                />
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}pricing/edit/:packageId`}
                    component={(routeProps) => {
                      const packageId = routeProps.match.params.packageId;
                      const locationState = routeProps.location.state;

                      return (
                          <EditPricingSessionForm
                              packageId={packageId}
                              {...locationState}
                          />
                      );
                    }}
                    pageTitle={"Edit Package"}
                    goBackURL={"packages"}
                    withConfirmation={true}
                />
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}roles`}
                    component={Roles}
                    pageTitle={"Roles"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}roles/new`}
                    component={RolesForm}
                    pageTitle={"Roles form"}
                    goBackURL={"roles"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}roles/edit/:id`}
                    component={RolesForm}
                    pageTitle={"Edit Role"}
                    goBackURL={"roles"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}locations/overall_stats`}
                    component={OverallStats}
                    pageTitle={"Overall Stats"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}locations/item_stats`}
                    component={ItemsStats}
                    pageTitle={"Item Stats"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}locations/modifiers`}
                    component={Modifiers}
                    pageTitle={"Modifiers"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}locations/menu`}
                    component={Menu}
                    pageTitle={"Menu"}
                />
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}locations/services`}
                    component={Menu}
                    pageTitle={"Services"}
                />
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}locations`}
                    component={Locations}
                    pageTitle={"Select Restaurant"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}hotels`}
                    component={Hotels}
                    pageTitle={"Select Hotel"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}room`}
                    component={Rooms}
                    pageTitle={"Select Room"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}editroom`}
                    component={EditForm}
                    pageTitle={"Select Room"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}listroom`}
                    component={ListRooms}
                    pageTitle={"List Room"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}locations/all`}
                    component={AllLocations}
                    pageTitle={"Restaurants"}
                    // goBackURL={'Dashboard'}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}packages/all`}
                    component={AllPackages}
                    pageTitle={"Packages"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}packages/show`}
                    component={ShowPackages}
                    pageTitle={"Show Packages"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}packages/checkout`}
                    component={Checkout}
                    pageTitle={"Checkout"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}locations/all/:staffid`}
                    component={AllLocations}
                    pageTitle={"Restaurants"}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}customers`}
                    component={CustomerList}
                    pageTitle={"Customer List"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}packages`}
                    component={packageSession}
                    pageTitle={"Packages"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}pricing`}
                    component={pricingsession}
                    pageTitle={"Prices"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}locations/new`}
                    component={NewLocation}
                    pageTitle={"New Restaurant"}
                    goBackURL={"locations"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}hotels/new`}
                    component={NewHotel}
                    pageTitle={"New Hotel"}
                    goBackURL={"hotels"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}locations/:location_id`}
                    component={Location}
                    pageTitle={"Restaurant Details"}
                    goBackURL={"locations"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}hotels/:location_id`}
                    component={Hotel}
                    pageTitle={"Hotels Details"}
                    goBackURL={"hotels"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}room/:location_id`}
                    component={Room}
                    pageTitle={"Room Details"}
                    goBackURL={"room"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}dish_tags`}
                    component={DishTags}
                    pageTitle={"Dish Tags"}
                    goBackURL={"Dashboard"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}allergic_items`}
                    component={AllergicItems}
                    pageTitle={"Allergic Items"}
                    goBackURL={"Dashboard"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}allergic_items/add`}
                    component={AllergicItemsForm}
                    pageTitle={"Add New Allergic Item"}
                    goBackURL={"allergic_items"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}allergic_items/edit/:item_id`}
                    component={AllergicItemsForm}
                    pageTitle={"Update Allergic Item"}
                    goBackURL={"allergic_items"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}dish_tags/edit/:tag_id`}
                    component={DishTagForm}
                    goBackURL={"dish_tags"}
                    pageTitle={"Edit Dish Tag"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}dish_tags/add`}
                    component={DishTagForm}
                    goBackURL={"dish_tags"}
                    pageTitle={"Edit Dish Tag"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}send/popups`}
                    component={SendPopupsOnTabs}
                    pageTitle={"Send Popups on tabs"}
                    goBackURL={"Dashboard"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}location/terms_conditions`}
                    component={TermsConditions}
                    pageTitle={"Welcome text"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}location/reviews`}
                    component={Review}
                    pageTitle={"Reviews"}
                    withConfirmation={true}
                />
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}checkout_message`}
                    component={CheckoutMessage}
                    pageTitle={"Checkout Message"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}branding`}
                    component={Branding}
                    pageTitle={"Branding"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}notification_list`}
                    component={NotificationList}
                    pageTitle={"Notification List"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}survey_questions`}
                    component={Questionnaire}
                    pageTitle={"Survey Questionnaire"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}rooms`}
                    component={Rooms}
                    pageTitle={"Rooms"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}survey_list`}
                    component={SurveyResponse}
                    pageTitle={"Survey Response List"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}location/stats`}
                    component={LocationStats}
                    pageTitle={"Location Stats"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}location/timeslots`}
                    component={LocationTimeSlots}
                    pageTitle={"Location TimeSlots"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}selected-time-slots`}
                    component={SelectedTimeSlotsPage}
                    pageTitle={"SelectedTimeSlots"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}license`}
                    component={License}
                    pageTitle={"Coming Soon"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}upcoming_events`}
                    component={UpcomingEvents}
                    pageTitle={"Upcoming Events"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}payment`}
                    component={Payment}
                    pageTitle={"Coming Soon"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}location/upload/pdf`}
                    component={Pdf}
                    pageTitle={"Menu PDF"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}faq`}
                    component={Faq}
                    pageTitle={"Coming Soon"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}wiki`}
                    component={Wiki}
                    pageTitle={"Coming Soon"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}previews`}
                    component={Previews}
                    pageTitle={"Coming Soon"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}contactus`}
                    component={Contact}
                    pageTitle={"Coming Soon"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}emergency`}
                    component={Emergency}
                    pageTitle={"Coming Soon"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}training`}
                    component={Training}
                    pageTitle={"Coming Soon"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}waiter`}
                    component={Waiter}
                    pageTitle={"Coming Soon"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}eater`}
                    component={Eater}
                    pageTitle={"Coming Soon"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}kitchen`}
                    component={Kitchen}
                    pageTitle={"Coming Soon"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}website`}
                    component={Website}
                    pageTitle={"Coming Soon"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}menu/mobile-preview`}
                    component={CategoriesMobilePreview}
                    pageTitle={"Menu Categories Preview"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}menu/menu-mobile-preview`}
                    component={MenuMobilePreview}
                    pageTitle={"Menu Mobile Preview"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}menu/menu-option-preview`}
                    component={MenuOptionsPreview}
                    pageTitle={"Menu Options Preview"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}floors`}
                    component={FloorsManagement}
                    pageTitle={"Floor Management"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}floors/tables`}
                    component={FloorTablesManagement}
                    pageTitle={"Floor Tables Management"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}menu/creation`}
                    component={MenuItemPage}
                    pageTitle={"Menu Creation"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}payment/new`}
                    component={AddPayment}
                    pageTitle={"Payment Method"}
                    withConfirmation={true}
                />{" "}
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}payment/table`}
                    component={Paymentlist}
                    pageTitle={"Payment Method"}
                    withConfirmation={true}
                />
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}premium_services`}
                    component={Premium_Services}
                    pageTitle={'Premium Services'}
                />
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}premium_services/new`}
                    component={AddNewPremiumService}
                    pageTitle={'Premium Services'}
                />
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}premium_services/edit`}
                    component={UpdateNDeletePremiumService}
                    pageTitle={'Premium Services'}
                />
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}reservations`}
                    component={Reservations}
                    pageTitle={'Reservations'}
                />
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}reservation/new`}
                    component={NewReservation}
                    pageTitle={'Reservations'}
                />
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}reservation/edit`}
                    component={UpdateNDeleteReservation}
                    pageTitle={'Reservations'}
                />
                <PrivateRoute
                    exact
                    path={`${CONSTANTS.BASE_URL}printBill/:orderId`}
                    component={BillPrint}
                    pageTitle={"Bill Print"}
                    poslayout={true}
                />
              </Switch>{" "}
            </div>{" "}
          </BrowserRouter>{" "}
        </Layout>{" "}
      </I18nextProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
