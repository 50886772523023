
import CONSTANTS from "../../../../constants";

export function allTables(state = { data: [], loading: true }, action) {
    if (action.type === CONSTANTS.ACTIONS.POS.ALL_TABLES) return { data: action.tables, loading: action.loading };
    return state;
}

export function selectedTable(state = {table: null}, action) {
    if (action.type === CONSTANTS.ACTIONS.POS.SELECTED_TABLE) return {table: action.table};
    return state
}