import React from 'react';
import {AppBar, Toolbar, useMediaQuery, useTheme} from "@mui/material";
import {grey} from "@mui/material/colors";
import LargeScreenNavbarLeftSide from "./largeScreenNavbarLeftSide";
import LargeScreenNavbarRightSide from "./largeScreenNavbarRightSide";
import MediumScreenNavbarLeftSide from "../../components/navbar/mediumScreen/mediumScreenNavbarLeftSide";
import MediumScreenNavbarRight from "../../components/navbar/mediumScreen/mediumScreenNavbarRightSide";
import SmallScreenNavbar from "./smallScreenNavbar";

const PosNavbar = () => {
    const theme = useTheme()
    const sm = useMediaQuery(theme.breakpoints.down('md'));
    const md = useMediaQuery(theme.breakpoints.down('xl'));

    return (
        <AppBar position='static' sx={{height: 'fit-content', backgroundColor: 'white', boxShadow: 'none', border: `1px solid ${grey[200]}`}}>
            <Toolbar sx={{'&.MuiToolbar-root': {minHeight: {xs: '49px', sm: '49px', md: '49px'}}, columnGap: '1rem', paddingX: {xs: '0.25rem', sm: '0.5rem'}}}>
                {
                    (!sm && !md) ? (
                        <>
                            <LargeScreenNavbarLeftSide />
                            <LargeScreenNavbarRightSide />
                        </>
                    ) : (md && !sm) ? (
                        <>
                            <MediumScreenNavbarLeftSide />
                            <MediumScreenNavbarRight />
                        </>
                    ) : (
                        <>
                            <SmallScreenNavbar />
                        </>
                    )
                }
            </Toolbar>
        </AppBar>
    )
}

export default PosNavbar;