import React from 'react';
import RecentOrdersSearch from "../../components/mainScreen/leftSectionComponents/recentOrdersSearch";
import RecentOrderHistory from "../../components/mainScreen/leftSectionComponents/recentOrderHistory";
import OrderPlacementButtons from "../../components/mainScreen/leftSectionComponents/orderPlacementButtons";
import {Drawer, Stack} from "@mui/material";

const LeftSectionDrawer = ({open, setOpenDrawer}) => {
    return (
        <Drawer open={open} onClose={() => setOpenDrawer(false)}>
            <Stack sx={{backgroundColor: 'white', borderRadius: '0.25rem', width: '200px', minWidth: '200px', height: '100%'}}>
                <RecentOrdersSearch/>
                <RecentOrderHistory/>
                <OrderPlacementButtons/>
            </Stack>
        </Drawer>
    )
};

export default LeftSectionDrawer;