import React, {useState} from 'react';
import {Button, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {orange, purple, red} from "@mui/material/colors";
import {
    BrunchDining,
    CollectionsBookmark,
    LocalShipping,
    Person,
    ShoppingBag,
    TableRestaurant
} from "@mui/icons-material";
import {setOrderType} from "../../../../../redux/actions/pos/order";
import {useDispatch} from "react-redux";
import LeftSectionDrawer from "../../../sections/mainScreen/leftSectionDrawer";

const buttonStyles = {
    textTransform: 'capitalize',
    borderRadius: '0.25rem',
    padding: '0.35rem 0.5rem',
    textAlign: 'center',
    lineHeight: '1.5rem',
    flexGrow: 1,
    color: 'white',
    cursor: 'pointer',
    boxShadow: '0 0 5px rgba(0,0,0,0.25)',
}

const buttons = [
    {
        label: 'Ongoing Orders',
        icon: <BrunchDining />,
        backgroundColor: red[400],
        hoverBackgroundColor: red[600]
    },

    {
        label: 'Type',
        icon: <CollectionsBookmark />,
        backgroundColor: purple[400],
        hoverBackgroundColor: purple[600]
    },

    {
        label: 'Customer',
        icon: <Person />,
        backgroundColor: orange[400],
        hoverBackgroundColor: orange[600]
    }
]

const typeMenu = [
    {
        label: 'Dine In',
        icon: <BrunchDining />
    },

    {
        label: 'Takeaway',
        icon: <ShoppingBag />
    },

    {
        label: 'Delivery',
        icon: <LocalShipping />
    },
]

const MediumScreenNavbarLeftSide = () => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleButtonClick = (index, event) => {
        if (index === 0) {
            setOpenDrawer(true);
        } else if (index === 1) {
            setAnchorEl(event.currentTarget);
        } else if (index === 3) {

        }
    }

    const handleOrderTypeButtonClick = async (index) => {
        if (index === 0) {
            await dispatch(setOrderType({
                order_type: 'Dine In'
            }))
        } else if (index === 1) {
            await dispatch(setOrderType({
                order_type: 'Takeaway'
            }))
        } else if (index === 2) {
            await dispatch(setOrderType({
                order_type: 'Delivery'
            }))
        }
    }

    return(
        <>
            <Stack direction='row' sx={{width: '50%', alignItems: 'center', columnGap: '0.75rem'}}>
                {
                    buttons.map((item, index) => (
                        <Button onClick={(event)=> handleButtonClick(index, event)} variant='contained' startIcon={item.icon} key={index} sx={{...buttonStyles, backgroundColor: item.backgroundColor, '&:hover': {backgroundColor: item.hoverBackgroundColor}}}>
                            {item.label}
                        </Button>
                    ))
                }
                <Menu
                    id="order_type-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'order_type_menu',
                    }}
                >
                    {typeMenu.map((menuItem, index)=> (
                        <MenuItem key={index} sx={{columnGap: '1rem'}} onClick={()=> handleOrderTypeButtonClick(index)}>
                            {menuItem.icon}
                            <Typography variant='h6' sx={{fontSize: '1rem'}}>{menuItem.label}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Stack>
            <LeftSectionDrawer open={openDrawer} setOpenDrawer={setOpenDrawer} />
        </>
    )
}

export default MediumScreenNavbarLeftSide;