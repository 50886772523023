import React, {useRef, useState} from 'react';
import {Stack, Button} from "@mui/material";
import {grey} from "@mui/material/colors";
import {DesignServices, Info, Print, DoDisturb} from '@mui/icons-material';
import FinalPaymentModal from "../../mainComponents/finalPaymentModal/finalPaymentModal";
import OrderDetailModal from "../../mainComponents/orderDetailModal";
import {useDispatch, useSelector} from "react-redux";
import SnackBar from "../../mainComponents/snackBar";
import CancelOrderModal from "../../mainComponents/cancelOrderModal";
import {setIsModifyOrder} from "../../../../../redux/actions/pos/order";
import {clearCart} from "../../../../../redux/actions/cart";
import BillPrint from "../../mainComponents/billPrint/billPrint";
import {useReactToPrint} from "react-to-print";
import CONSTANTS from "../../../../../constants";

const buttonStyle = {
    backgroundColor: grey[200],
    textTransform: 'capitalize',
    borderRadius: '0.25rem',
    padding: '0.5rem 0.5rem',
    lineHeight: '1.5rem',
    fontSize: '0.85rem',
    color: 'black',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: grey[400]
    }
}

const OrderPlacementButtons = () => {
    const dispatch = useDispatch();
    const selectedOrder = useSelector(state => state.pos.selectedOrder);
    const locationData = useSelector(state => state.pos.locationData);
    const [openModal, setOpenModal] = useState(false);
    const [openFinalizeSale, setOpenFinalizeSale] = useState(false);
    const [openCancelOrder, setOpenCancelOrder] = useState(false);

    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        message: '',
        type: ''
    });

    const handleCancelOrder = () => {
        if (!selectedOrder || !selectedOrder.order_id) {
            setSnackbarParams({
                open: true,
                message: "Please, select an order",
                severity: 'error'
            })
        } else {
            setOpenCancelOrder(true);
        }
    }

    const handleShowOrderDetails = () => {
        if (!selectedOrder || !selectedOrder.order_id) {
             setSnackbarParams({
                 open: true,
                 message: "Please, select an order",
                 severity: 'error'
             })
        } else {
            setOpenModal(true);
        }
    }

    const handleShowInvoice = () => {
        if (!selectedOrder || !selectedOrder.order_id) {
            setSnackbarParams({
                open: true,
                message: "Please, select an order",
                severity: 'error'
            })
        } else {
            setOpenFinalizeSale(true);
        }
    }

    const handleModifyOrder = async () => {
        await dispatch(setIsModifyOrder(false));
        if (!selectedOrder || !selectedOrder.order_id) {
            setSnackbarParams({
                open: true,
                message: "Please, select an order",
                severity: 'error'
            })
        } else {
            dispatch(clearCart());
            await dispatch(setIsModifyOrder(true));
        }
    }

    const handleBillPrint = () => {
        if (!selectedOrder || !selectedOrder.order_id) {
            setSnackbarParams({
                open: true,
                message: "Please, select an order",
                severity: 'error'
            })
        } else {
            const orderData = [selectedOrder, locationData];
            localStorage.setItem("orderData", JSON.stringify(orderData));
            const newTabUrl = `${CONSTANTS.BASE_URL}printBill/${selectedOrder.order_id}`;
            const newTab = window.open(newTabUrl, '_blank');
            newTab.focus();
        }
    }

    return(
        <>
            <Stack sx={{padding: '0.5rem', rowGap: '0.35rem'}}>
                <Button startIcon={<DesignServices />} variant='contained' sx={buttonStyle} onClick={()=> handleModifyOrder()}>
                    Modify Order
                </Button>
                <Button startIcon={<Info />} variant='contained' sx={buttonStyle} onClick={()=> handleShowOrderDetails()}>
                    Order Detail
                </Button>
                <Button startIcon={<Print />} variant='contained' sx={buttonStyle}>
                    Re-Print
                </Button>
                <Stack direction='row' columnGap={'0.35rem'} alignItems={'center'}>
                    <Button sx={buttonStyle} variant='contained' fullWidth onClick={()=> handleShowInvoice()}>Invoice</Button>
                    <Button sx={buttonStyle} variant='contained' fullWidth onClick={()=> handleBillPrint()}>Bill</Button>
                </Stack>
                <Button startIcon={<DoDisturb />} sx={buttonStyle} variant='contained' onClick={()=> handleCancelOrder()}>Cancel Order</Button>
            </Stack>
            {openModal && <OrderDetailModal setOpenModal={setOpenModal} openModal={openModal}
                                            order={selectedOrder} setOpenFinalizeSale={setOpenFinalizeSale}/>}
            {openFinalizeSale && <FinalPaymentModal openModal={openFinalizeSale} setOpenModal={setOpenFinalizeSale} order={selectedOrder}/>}
            {openCancelOrder && <CancelOrderModal openModal={openCancelOrder} setOpenModal={setOpenCancelOrder} setSnackbarParams={setSnackbarParams}/>}
            <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
        </>
    )
}

export default OrderPlacementButtons;