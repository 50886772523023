import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Grid, IconButton, Modal, Stack, Typography} from "@mui/material";
import {finalOrderModalStyles, itemModal, selectTableModal} from "../../../../assets/posStyles";
import CloseIcon from "@mui/icons-material/Close";
import {green, grey} from "@mui/material/colors";
import {useDispatch, useSelector} from "react-redux";
import {setLocationTables, setSelectedTableRedux} from "../../../../redux/actions/pos/tables";
import booked_table from "../../../../assets/img/pages/table_booked.png";
import free_table from "../../../../assets/img/pages/table_empty.png";
import SnackBar from "./snackBar";
import NewSessionAgreementModal from "./newSessionAgreementModal";

const gridItemStyle = {
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    boxShadow: '0 0 2px rgba(0,0,0,0.5)',
    transition: '0.5s !important',
    textAlign: 'center',
    overflow: 'hidden',
    height: '100%',
    cursor: 'pointer'
}

const TableSelectModal = ({openModal, setOpenModal, setOpenPaymentModal}) => {
    const dispatch = useDispatch();
    const locationData = useSelector((state)=> state.pos.locationData);
    const tables = useSelector((state) => state.pos.allTables.data.data);
    const loading = useSelector(state => state.pos.allTables.loading);
    const [selectedTable, setSelectedTable] = useState(null);
    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        message: "",
        severity: ""
    })
    const [openAgreementModal, setOpenAgreementModal] = useState({
        open: false,
        table: null
    });

    const setTablesData = async () => {
        if (locationData && locationData.loc_id) {
            await dispatch(setLocationTables(locationData.loc_id));
        }
    }

    useEffect(() => {
        setTablesData()
    }, [locationData]);

    const handleModalClose = () => {
        setOpenModal(false);
    }

    const setTable = async () => {
        await dispatch(setSelectedTableRedux(selectedTable));
        setSnackbarParams({
            open: true,
            message: "Table Saved Successfully",
            severity: "success"
        })
        setOpenModal(false);
    }

    const handleTableSelect = (table) => {
        if (table?.LocationSessions && table.LocationSessions.length > 0 && table.LocationSessions[0].session_id) {
            setOpenAgreementModal({
                open: true,
                table: table
            });
        } else {
            setSelectedTable(table);
            setSnackbarParams({
                open: true,
                message: `Table ${table.table_name} selected`,
                severity: "success"
            })
        }
    }

    return(
        <>
            <Modal open={openModal} onClose={()=> setOpenModal(false)} sx={itemModal.modalStyle}>
                <Stack sx={selectTableModal.mainStack}>
                    <Stack direction='row' sx={{padding: '0.5rem 1rem', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>Select Table</Typography>
                        <IconButton size='small' onClick={()=> handleModalClose()}>
                            <CloseIcon sx={{height: '20px', width: '20px', color: 'black'}}/>
                        </IconButton>
                    </Stack>
                    <Box sx={{height: '1px', width: '100%', backgroundColor: grey[200]}} />
                    <Stack direction='row' sx={{padding: '0.5rem', backgroundColor: grey[200], columnGap: '0.5rem'}}>
                        {/* <Stack sx={{borderRadius: '4px', backgroundColor: 'white', width: '175px', padding: '0.5rem'}}>
                            <Typography variant='h6' sx={{fontSize: '1rem', marginX: 'auto'}}>Area/Floor</Typography>
                        </Stack> */}
                        <Stack sx={{borderRadius: '4px', backgroundColor: 'white', flexGrow: 1, padding: '0.5rem', overflowY: 'auto', maxHeight: 'calc(100vh - 200px)'}}>
                            {loading ? <CircularProgress color='success' sx={{marginX: 'auto'}} /> :
                                ((tables && tables.length > 0) ?
                                        <Grid container spacing={2}>
                                            {tables.map((table, index) => (
                                                <Grid key={index} item xs={12} sm={4} md={4} lg={3} alignItems={'stretch'}>
                                                    <Stack sx={gridItemStyle} onClick={()=> handleTableSelect(table)}>
                                                        <Box sx={{height: '80px', width: '80px', marginX: 'auto'}} component='img' src={table.is_table_available ? free_table : booked_table} alt={'Table'} />
                                                        <Stack sx={{alignItems: 'center', justifyContent: 'center', padding: '0.5rem', flexGrow: 1}}>
                                                            <Stack direction='row' sx={{marginBottom: '0.5rem', alignItems: 'center', justifyContent: (table.is_table_available ? 'center' : 'space-between'), width: '100%'}}>
                                                                <Typography variant='h6' sx={{fontSize: '0.9rem'}}>{table.table_name}</Typography>
                                                                {!table.is_table_available && <Typography variant='h6' sx={{fontSize: '0.75rem', backgroundColor: green[600], padding: '0.25rem 0.5rem', borderRadius: '0.25rem', color: 'white'}}>{table.LocationSessions[0].order_from}</Typography> }
                                                            </Stack>
                                                            <Typography variant='body2'>Min. Capacity {table.min_capacity}</Typography>
                                                            <Typography variant='body2'>Max. Capacity {table.max_capacity}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        : (
                                            <Typography variant='h6' sx={{marginX: 'auto'}}>No Tables Found</Typography>
                                        )
                                )
                            }
                        </Stack>
                    </Stack>
                    <Box sx={{height: '1px', width: '100%', backgroundColor: grey[200]}} />
                    <Stack direction='row' sx={{padding: '0.5rem 1rem', alignItems: 'center', columnGap: '1rem'}}>
                        <Button variant='contained' onClick={()=> setTable()} sx={finalOrderModalStyles.saveButton}>Save</Button>
                        <Button variant='contained' onClick={()=> setOpenModal(false)} sx={finalOrderModalStyles.closeButton}>Cancel</Button>
                    </Stack>
                </Stack>
            </Modal>
            <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
            {openAgreementModal.open && <NewSessionAgreementModal setOpenPaymentModal={setOpenPaymentModal} open={openAgreementModal} setOpenModal={setOpenAgreementModal} setSelectedTable={setSelectedTable} /> }
        </>
    )
}

export default TableSelectModal;