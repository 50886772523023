import {finalOrderModalStyles} from "../../../../assets/posStyles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import OrderService from "../../../../services/OrderService";
import {getPosOrderHistory, setSelectedOrder} from "../../../../redux/actions/pos/order";

const CancelOrderModal = ({openModal, setOpenModal, setSnackbarParams}) => {
    const dispatch = useDispatch();
    const selectedOrder = useSelector(state => state.pos.selectedOrder);

    const handleDeleteOrder = async () => {
        const response = await OrderService.deletePOSOrder(selectedOrder.order_id);
        if (response.error) {
            setSnackbarParams({
                open: true,
                message: "Failed to Delete Order",
                severity: 'error'
            })
        } else {
            setSnackbarParams({
                open: true,
                message: "Order Deleted",
                severity: 'success'
            });
            dispatch(getPosOrderHistory())
            dispatch(setSelectedOrder(null));
            setOpenModal(false);
        }
    }

    const handleCancelOrder = () => {
        setOpenModal(false);
    }

    return(
        <>
            <Dialog
                open={openModal}
                keepMounted
                onClose={()=> setOpenModal(false)}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{"Cancel Order?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please, confirm if you want to delete order
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={finalOrderModalStyles.closeButton} variant='contained' onClick={()=> handleDeleteOrder()} >Delete Order</Button>
                    <Button sx={finalOrderModalStyles.saveButton} variant='contained' onClick={()=> handleCancelOrder()}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CancelOrderModal;