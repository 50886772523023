import React from 'react';
import {itemModal, openBalanceModal, saveButtonStyle} from "../../../../assets/posStyles";
import {Button, CircularProgress, Divider, Grid, Modal, Stack, TextField, Typography} from "@mui/material";
import {useState} from "react";
import SnackBar from "./snackBar";
import {openRegister} from "../../../../services/RegisterService";
import {useDispatch} from "react-redux";
import {setRegisterData} from "../../../../redux/actions/pos/register";

const fields = [
    {
        label: 'cashBalance',
        name: "Cash",
        type: "text"
    },

    // {
    //     label: "cardBalance",
    //     name: "Card",
    //     type: "text"
    // },
    //
    // {
    //     label: "bankBalance",
    //     name: "Bank",
    //     type: "text"
    // },
    //
    // {
    //     label: "chequeBalance",
    //     name: "Cheque",
    //     type: "text"
    // }
]

const OpeningBalanceModal = ({openModal, setOpenModal}) => {
    const dispatch = useDispatch();
    const [fieldsData, setFieldsData] = useState({
        cashBalance: "",
        // cardBalance: "",
        // bankBalance: "",
        // chequeBalance: ""
    })
    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        message: "",
        severity: ""
    })
    const [loading, setLoading] = useState(false);

    const handleSaveBalance = async () => {
        setLoading(true);
        if (!fieldsData.cashBalance || fieldsData.cashBalance === '') {
            setSnackbarParams({
                open: true,
                message: "Enter Cash Amount",
                severity: "error"
            })
            setLoading(false);
            return;
        }
        // } else if (!fieldsData.cardBalance || fieldsData.cashBalance === '') {
        //     setSnackbarParams({
        //         open: true,
        //         message: "Enter Card Amount",
        //         severity: "error"
        //     })
        //     return;
        // } else if (!fieldsData.chequeBalance || fieldsData.chequeBalance === '') {
        //     setSnackbarParams({
        //         open: true,
        //         message: "Enter Cheque Amount",
        //         severity: "error"
        //     })
        //     return;
        // } else if (!fieldsData.bankBalance || fieldsData.bankBalance === '') {
        //     setSnackbarParams({
        //         open: true,
        //         message: "Enter Bank Amount",
        //         severity: "error"
        //     })
        //     return;
        // }
        const response = await openRegister({
            opening_balance: parseFloat(fieldsData.cashBalance),
            date_opened: new Date()
        });

        if (!response.error) {
            await dispatch(setRegisterData(response.data.data));
            setOpenModal(false)
        } else {
            setSnackbarParams({
                open: true,
                message: "Cannot Open Register Balance",
                severity: "error"
            })
        }
        setLoading(false);
    }

    const handleBalanceChange = (value, name) => {
        setFieldsData({...fieldsData, [name]: value});
    }

    return(
        <>
            <Modal open={openModal} sx={itemModal.modalStyle}>
                <Stack sx={openBalanceModal.mainStack}>
                    <Typography variant='h6' sx={{fontSize: '1.15rem', fontWeight: '600'}}>Opening Balance</Typography>
                    <Divider sx={{marginY: '0.5rem'}}/>
                    <Typography variant='body2'>
                        Welcome! Please enter the opening balance to start your shift. Ensure that the amount entered
                        accurately reflects the cash in the register to avoid discrepancies at the end of the day. Thank you!
                    </Typography>
                    <Grid container spacing={1}>
                        {fields.map((item, index) => (
                            <Grid key={index} item xs={12} md={12}>
                                <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>{item.name}</Typography>
                                <TextField
                                    size="small"
                                    variant='outlined' value={fieldsData[item.label]} required type={'number'}
                                    fullWidth placeholder={`Please, Enter ${item.name} Balance`}
                                    onChange={(e) => handleBalanceChange(e.target.value, item.label)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Divider sx={{marginY: '1rem'}}/>
                    <Button disabled={loading} variant='contained' onClick={()=> handleSaveBalance()}
                            sx={{...saveButtonStyle}}
                    >{loading ? <CircularProgress size={20} color='success' /> : "Save"}</Button>
                </Stack>
            </Modal>
            <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
        </>
    )
}

export default OpeningBalanceModal;