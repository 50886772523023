import {addItemsToCart, getCartTotal, removeItem, updateItemsInCart} from "../redux/actions/cart";

export const handleIncrement = (itemIndex, dispatch, cart, filterItems, setRItems, removeItems) => {
    const updatedCart = cart.cart.map((item, index) => {
        if (index === itemIndex) {
            const filterItem = filterItems.find((filterItem) => filterItem.menu_id === item.menu_id);
            if (filterItem && filterItem.order_menu_id) {
                if (filterItem.order_menu_id === item.order_menu_id && (item.itemCount + 1 !== filterItem.mainQty) && item.isModifying) {
                    if (!removeItems.includes(filterItem.order_menu_id)) {
                        setRItems((prev) => [...prev, item.order_menu_id]);
                    }
                } else {
                    const data = removeItems;
                    for (let dataIndex = 0; dataIndex < data.length; dataIndex++) {
                        if (data[dataIndex] === filterItem.order_menu_id) {
                            data.splice(dataIndex, 1);
                            break;
                        }
                    }
                    setRItems(data);
                }
            }
            const updatedItem = {
                ...item,
                itemCount: item.itemCount + 1,
                isModified: ((item.isModifying && item.menu_id === filterItem.menu_id) && (item.itemCount + 1) !== filterItem.mainQty),
                order_menu_tax:
                    (item.order_menu_tax / item.itemCount) * (item.itemCount + 1),
                MenuOptions: item.MenuOptions.map((menuOption) => ({
                    ...menuOption,
                    Option_Values: menuOption.Option_Values.map((optionValue) => {
                        let initialOptionCount = optionValue.initialOptionCount;

                        if (initialOptionCount === undefined) {
                            initialOptionCount = optionValue.optionCount;
                        }

                        const updatedOptionValue = {
                            ...optionValue,
                            optionCount: optionValue.optionCount + initialOptionCount,
                            quantity: optionValue.optionCount + initialOptionCount,
                            initialOptionCount,
                        };
                        return updatedOptionValue;
                    }),
                })),
            };
            return updatedItem;
        }
        return item;
    });

    dispatch(removeItem({ itemIndex, items: updatedCart }));
}

export const handleDecrement = (itemIndex, dispatch, cart, filterItems, setRItems, removeItems) => {
    const updatedCart = cart.cart.map((item, index) => {
        if (index === itemIndex) {
            const filterItem = filterItems.find((filterItem) => filterItem.menu_id === item.menu_id);
            if (filterItem && filterItem.order_menu_id) {
                if (filterItem && filterItem.order_menu_id === item.order_menu_id && (item.itemCount - 1 !== filterItem.mainQty) && item.isModifying) {
                    if (!removeItems.includes(filterItem.order_menu_id)) {
                        setRItems((prev) => [...prev, item.order_menu_id]);
                    }
                } else {
                    const data = removeItems;
                    for (let dataIndex = 0; dataIndex < data.length; dataIndex++) {
                        if (data[dataIndex] === filterItem.order_menu_id) {
                            data.splice(dataIndex, 1);
                            break;
                        }
                    }
                    setRItems(data);
                }
            }
            const updatedItem = {
                ...item,
                itemCount: item.itemCount > 1 ? item.itemCount - 1 : 1,
                isModified: ((item.isModifying && item.menu_id === filterItem.menu_id) && (item.itemCount - 1) !== filterItem.mainQty),
                order_menu_tax:
                    (item.order_menu_tax / item.itemCount) * (item.itemCount - 1),
                MenuOptions: item.MenuOptions.map((menuOption) => ({
                    ...menuOption,
                    Option_Values: menuOption.Option_Values.map((optionValue) => {
                        let initialOptionCount = optionValue.initialOptionCount;

                        if (initialOptionCount === undefined) {
                            initialOptionCount = optionValue.optionCount;
                        }

                        const newOptionCount =
                            optionValue.optionCount > initialOptionCount
                                ? optionValue.optionCount - initialOptionCount
                                : initialOptionCount;

                        return {
                            ...optionValue,
                            optionCount: newOptionCount,
                            quantity: newOptionCount,
                            initialOptionCount,
                        };
                    }),
                })),
            };
            return updatedItem;
        }
        return item;
    });

    dispatch(removeItem({ itemIndex, items: updatedCart }));
}

export const removeCartItem = (itemIndex, dispatch, cart, setRItems) => {
    let remainItems = [];
    cart.cart.map((item, index) => {
        if (index !== itemIndex) {
            remainItems.push(item);
        } else {
            setRItems((prev) => [...prev, item.order_menu_id]);
        }
    });

    dispatch(removeItem({ itemIndex, items: remainItems }));
}

export const addToCart = (subTotal, totalTax, totalAmount, dispatch, selectedRadioCartItem, selectedCheckboxCartItem, selectedSelectCartItem, item, cartItemsDataList, quantity, specialInstruction, update) => {
    let selectedValuesForCart = [];
    selectedRadioCartItem.forEach((item) => {
        selectedValuesForCart.push(item.option_id);
    });

    selectedCheckboxCartItem.forEach((item) => {
        selectedValuesForCart.push(item.option_id);
    });

    selectedSelectCartItem.forEach((item) => {
        selectedValuesForCart.push(item.option_id);
    });

    for (let i = 0; i < item.MenuOptions.length; i++) {
        let requiredFound = false;
        if (selectedValuesForCart.length === 0) {
            if (item.MenuOptions[i].required !== 1) {
                requiredFound = true;
            }
        } else {
            for (let j = 0; j < selectedValuesForCart.length; j++) {
                if (
                    item.MenuOptions[i].required === 1 &&
                    item.MenuOptions[i].option_id === selectedValuesForCart[j]
                ) {
                    requiredFound = true;
                } else if (item.MenuOptions[i].required !== 1) {
                    requiredFound = true;
                }
            }
        }

        // if (!requiredFound) {
        //     setOpenSnackBar(true);
        //     setOpenSnackBarMsg(t("Please, Select All the Required Options"));
        //     return;
        // }
    }

    for (let i = 0; i < item.MenuOptions.length; i++) {
        let minFound = false;
        //////(item.MenuOptions[i], "item.MenuOptions");
        let errorMessage = [];

        // Check if the display_type is 'select'
        if (item.MenuOptions[i].Option.display_type === "select") {
            if (selectedSelectCartItem.length === 0) {
                if (item.MenuOptions[i].min !== 1) {
                    minFound = false;
                }
            } else {
                for (let j = 0; j < selectedSelectCartItem.length; j++) {
                    const selectedItem = selectedSelectCartItem[j];
                    const optionValues = selectedItem.Option_Values?.map(
                        (option) => option.value
                    );

                    ////(selectedItem, "selected");
                    const hasOptionCount = selectedItem.Option_Values?.map(
                        (option) => option.optionCount
                    );
                    const minOptionCount = Math.min(...hasOptionCount);
                    if (
                        minOptionCount < item.MenuOptions[i].min &&
                        item.MenuOptions[i].option_id === selectedItem.option_id
                    ) {
                        minFound = true;
                        // errorMessage = `${optionValues} (${minOptionCount}) `;
                        errorMessage.push({
                            value: optionValues,
                            // optionCount: minOptionCount,
                        });
                        // ////(errorMessage.map((item) => ////(item.value)));
                    } else {
                        minFound = false;
                    }
                }
            }
            errorMessage.forEach((item) => {
                item.value.forEach((value) => {
                    ////(value);
                });
            });
            if (minFound) {
                const errorMessageString = errorMessage
                    .map((item) => item.value.join(", "))
                    .join("; ");
                // setOpenSnackBar(true);
                // setOpenSnackBarMsg(
                //     (
                //         `Minimum-Quantity-of these Varients- ${errorMessageString} must be ${item.MenuOptions[i].min}`
                //     )
                // );
                return;
            }
        }
    }

    let cartItems = JSON.parse(JSON.stringify(cartItemsDataList));
    let menuItemsOptions = [];
    if (selectedRadioCartItem.length > 0) {
        menuItemsOptions = [...menuItemsOptions, ...selectedRadioCartItem];
    }

    if (selectedCheckboxCartItem.length > 0) {
        menuItemsOptions = [...menuItemsOptions, ...selectedCheckboxCartItem];
    }

    if (selectedSelectCartItem.length > 0) {
        menuItemsOptions = [...menuItemsOptions, ...selectedSelectCartItem];
    }

    let menuTax = (item.menu_price / 100) * item.menu_tax * quantity;
    if (update === 0) {
        cartItems.push({
            order_menu_id: item.order_menu_id,
            isModifying: item.isModifying,
            menu_id: item.menu_id,
            menu_name: item.menu_name,
            MenuOptions: menuItemsOptions,
            menu_type: item.menu_type,
            itemCount: quantity,
            menu_price: item.menu_price,
            comment: specialInstruction,
            order_menu_tax: parseFloat(menuTax.toFixed(2)),
            menu_tax_percentage: item.menu_tax,
            menu_photo: item.menu_photo,
        });

        dispatch(addItemsToCart(cartItems));

    } else {
        updateCartItemList(dispatch, item, specialInstruction, quantity, cartItems, menuItemsOptions, menuTax);
    }
    const payload = {
        subTotal: subTotal,
        totalTax: totalTax,
        totalAmount: totalAmount,
    };

    dispatch(getCartTotal(payload)); // Wrap payload in an array
    // resetValues();
}

const updateCartItemList = (dispatch, item, specialInstruction, quantity, cartItems, menuItemsOptions, menuTax) => {
    let found = false;
    let itemQuantity = 0;

    let newMenuItemOptionsArray = JSON.parse(JSON.stringify(menuItemsOptions));

    for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i].menu_id === item.menu_id) {
            if (cartItems[i].comment === specialInstruction) {
                found = true;
            } else {
                found = false;
                break;
            }

            if (
                newMenuItemOptionsArray.length === cartItems[i].MenuOptions.length
            ) {
                found = true;
                for (let j = 0; j < cartItems[i].MenuOptions.length; j++) {
                    //0 //6
                    for (let k = 0; k < newMenuItemOptionsArray.length; k++) {
                        //6 //6
                        if (
                            cartItems[i].MenuOptions[j].option_id ===
                            newMenuItemOptionsArray[k].option_id
                        ) {
                            found = true;
                            if (
                                cartItems[i].MenuOptions[j].Option_Values.length ===
                                newMenuItemOptionsArray[k].Option_Values.length
                            ) {
                                found = true;
                                if (cartItems[i].MenuOptions[j].Option_Values.length > 0) {
                                    for (
                                        let l = 0;
                                        l < cartItems[i].MenuOptions[j].Option_Values.length;
                                        l++
                                    ) {
                                        for (
                                            let m = 0;
                                            m < newMenuItemOptionsArray[k].Option_Values.length;
                                            m++
                                        ) {
                                            if (
                                                cartItems[i].MenuOptions[j].Option_Values[l]
                                                    .option_value_id ===
                                                newMenuItemOptionsArray[k].Option_Values[m]
                                                    .option_value_id
                                            ) {
                                                found = true;
                                                newMenuItemOptionsArray[k].Option_Values[
                                                    m
                                                    ].optionCount += parseInt(
                                                    cartItems[i].MenuOptions[j].Option_Values[l]
                                                        .optionCount
                                                );
                                                newMenuItemOptionsArray[k].Option_Values[
                                                    m
                                                    ].order_item_tax =
                                                    parseFloat(
                                                        newMenuItemOptionsArray[k].Option_Values[m]
                                                            .order_item_tax
                                                    ) +
                                                    parseFloat(
                                                        cartItems[i].MenuOptions[j].Option_Values[l]
                                                            .order_item_tax
                                                    ) *
                                                    parseInt(
                                                        cartItems[i].MenuOptions[j].Option_Values[l]
                                                            .optionCount
                                                    );
                                            } else {
                                                found = false;
                                            }
                                        }
                                        if (found) {
                                            break;
                                        }
                                    }
                                }
                            } else {
                                found = false;
                            }
                        } else {
                            found = false;
                        }

                        if (found) {
                            break;
                        }
                    }
                    if (!found) {
                        break;
                    }
                }

                if (found) {
                    itemQuantity =
                        parseInt(quantity) + parseInt(cartItems[i].itemCount);
                    cartItems[i].MenuOptions = newMenuItemOptionsArray;
                    cartItems[i].itemCount = itemQuantity;
                    cartItems[i].order_menu_tax =
                        parseFloat(cartItems[i].order_menu_tax) * itemQuantity;
                    break;
                }
            } else {
                found = false;
            }
        }
    }

    if (!found) {
        cartItems.push({
            order_menu_id: item.order_menu_id,
            isModifying: item.isModifying,
            menu_id: item.menu_id,
            menu_name: item.menu_name,
            MenuOptions: menuItemsOptions,
            menu_type: item.menu_type,
            itemCount: quantity,
            menu_price: item.menu_price,
            comment: specialInstruction,
            order_menu_tax: menuTax.toFixed(2) * quantity,
            menu_tax_percentage: item.menu_tax,
            menu_photo: item.menu_photo,
        });
    }
    // setCartItemsDataList(cartItems);
    dispatch(updateItemsInCart(cartItems));
};

export const handleCheckboxValueSelection = (valueId, selectedCheckboxes, menuOption, selectedCheckboxCartItem, quantity) => {
    let preSelectedCheckboxes = [];
    if (selectedCheckboxes.includes(valueId)) {
        preSelectedCheckboxes = selectedCheckboxes.filter((id) => id !== valueId);
    } else {
        preSelectedCheckboxes = [...selectedCheckboxes, valueId];
    }

    let radioTypeItm = {
        option_id: menuOption.option_id,
        display_type: "checbox",
        base_price: menuOption.base_price,
        option_name: menuOption.Option.option_name,
        Option_Values: [],
    };
    if (preSelectedCheckboxes.length > 0) {
        preSelectedCheckboxes.map((item) => {
            menuOption.MenuOptionValues.map((option) => {
                if (option.option_value_id === item) {
                    let price = option.new_price > 0 ? option.new_price : 0;
                    let itemtax =
                        menuOption.item_tax > 0 ? (price / 100) * menuOption.item_tax : 0;
                    radioTypeItm.Option_Values.push({
                        optionCount: quantity,
                        value: option.OptionValue.value,
                        option_value_id: option.option_value_id,
                        price: price,
                        order_item_tax: itemtax.toFixed(2),
                        order_item_tax_percentage:
                            menuOption.item_tax > 0 ? menuOption.item_tax : 0,
                        menu_option_type: menuOption.option_menu_type,
                        initialOptionCount: 1
                    });
                }
            });
        });
    }

    let arraySelectedChecboxCartItem = [...selectedCheckboxCartItem];
    let match = false;
    let maxSelectedValuesReached = false;
    arraySelectedChecboxCartItem.map((item, index) => {
        if (item.option_id === radioTypeItm.option_id) {
            match = true;
            arraySelectedChecboxCartItem[index] = radioTypeItm;
            if (
                menuOption.max !== 0 &&
                arraySelectedChecboxCartItem[index].Option_Values.length >
                menuOption.max
            ) {
                maxSelectedValuesReached = true;
            }
        }
    });
    if (!match) {
        arraySelectedChecboxCartItem.push(radioTypeItm);
    }

    // for removing empty option values
    let selectedItemsArray = [];
    arraySelectedChecboxCartItem.map((item) => {
        if (item.Option_Values.length > 0) {
            selectedItemsArray.push(item);
        }
    });

    // if (maxSelectedValuesReached) {
    //     setOpenSnackBar(true);
    //     setOpenSnackBarMsg(t("you-have-selected-maximum-options"));
    //     return;
    // }

    const arg1 = [...selectedCheckboxes, valueId];
    const arg2 = selectedItemsArray;
    return {arg1, arg2};
}

export const handleRadioValueSelection = (index, valueId, menuOption, setSelectedRadioCartItem, setSelectedRadioValues, quantity) => {
    let selectedOption = null;
    menuOption.MenuOptionValues.map((option) => {
        if (option.option_value_id === valueId) {
            selectedOption = option;
        }
    });

    let price = selectedOption.new_price > 0 ? selectedOption.new_price : 0;
    let itemtax =
        menuOption.item_tax > 0 ? (price / 100) * menuOption.item_tax : 0;
    let radioTypeItm = {
        option_id: selectedOption.option_id,
        display_type: "radio",
        base_price: menuOption.base_price,
        option_name: menuOption.Option.option_name,
        Option_Values: [
            {
                optionCount: quantity,
                value: selectedOption.OptionValue.value,
                option_value_id: selectedOption.option_value_id,
                price: price,
                order_item_tax: itemtax.toFixed(2),
                order_item_tax_percentage:
                    menuOption.item_tax > 0 ? menuOption.item_tax : 0,
                menu_option_type: menuOption.option_menu_type,
                initialOptionCount: 1
            },
        ],
    };
    let arraySelectedRadioCartItem = [...setSelectedRadioCartItem];
    let match = false;
    if (setSelectedRadioValues.length > 0) {
        arraySelectedRadioCartItem.map((item, index) => {
            if (item.option_id === radioTypeItm.option_id) {
                match = true;
                arraySelectedRadioCartItem[index] = radioTypeItm;
            }
        });
        if (!match) {
            arraySelectedRadioCartItem.push(radioTypeItm);
        }
    } else {
        arraySelectedRadioCartItem.push(radioTypeItm);
    }
    let arrayRadioItems = [...setSelectedRadioValues];
    arrayRadioItems[index] = valueId;

    // setSelectedRadioValues(arrayRadioItems);
    // setSelectedRadioCartItem(arraySelectedRadioCartItem);

    return {updatedSelectedRadioValues: arrayRadioItems, updatedSelectedRadioCartItem: arraySelectedRadioCartItem}
}

export const handleSelectValueSelection = (
    valueId,
    menuOption,
    action,
    selectedValues,
    selectValueCounts,
    selectedSelectCartItem,
    quantity
) => {
    const updatedValues = [...selectedValues];
    const updatedCounts = { ...selectValueCounts };

    if (updatedValues.includes(valueId)) {
        if (action === "increment") {
            updatedCounts[valueId] = (updatedCounts[valueId] || 0) + 1;
        }
    } else if (action === "increment") {
        updatedValues.push(valueId);
        updatedCounts[valueId] = 1;
    }

    // Construct the updated option item
    const radioTypeItm = {
        option_id: menuOption.option_id,
        display_type: "select",
        base_price: menuOption.base_price,
        option_name: menuOption.Option.option_name,
        Option_Values: []
    };

    // Populate Option_Values for the updated radioTypeItm
    updatedValues.forEach(item => {
        menuOption.MenuOptionValues.forEach(option => {
            if (option.option_value_id === item) {
                const price = option.new_price > 0 ? (option.new_price) : 0;
                const itemTax = menuOption.item_tax > 0 ? ((price * menuOption.item_tax / 100)) : 0;
                if (updatedCounts[item] > 0) {
                    radioTypeItm.Option_Values.push({
                        optionCount: (updatedCounts[item] * quantity),
                        value: option.OptionValue.value,
                        option_value_id: option.option_value_id,
                        price: price,
                        order_item_tax: itemTax.toFixed(2),
                        order_item_tax_percentage: menuOption.item_tax > 0 ? menuOption.item_tax : 0,
                        menu_option_type: menuOption.option_menu_type,
                        initialOptionCount: updatedCounts[item]
                    });
                }
            }
        });
    });

    // Update the cart items
    const updatedCartItems = selectedSelectCartItem.filter(item => item.option_id !== radioTypeItm.option_id);
    if (radioTypeItm.Option_Values.length > 0) {
        updatedCartItems.push(radioTypeItm);
    }

    // Update state
    return {updatedValues, updatedCounts, updatedCartItems};
}

export const handleRadioChange = (index, value, setSelectedRadioValues) => {
    ////(index, value, "handle");

    const updatedRadioValues = setSelectedRadioValues;
    if (updatedRadioValues[index] === value) {
        updatedRadioValues[index] = ""
    } else {
        updatedRadioValues[index] = value
    }
    return updatedRadioValues;
};

export const setItemsToModifyHelper = (selectedOrder, menuData, setFilteredOrderMenu) => {

    selectedOrder.OrderMenus.map((selectedOrderItem, index) => {
        menuData.map((mainMenuItem) => {
            mainMenuItem.Menu.map((subMenuItem) => {
                if (selectedOrderItem.menu_id === subMenuItem.menu_id) {
                    let filteredMenuOptions = [];
                    selectedOrder.OrderOptions.map((orderOption) => {
                        if (orderOption.menu_id === selectedOrderItem.menu_id && orderOption.order_menu_id === selectedOrderItem.order_menu_id) {
                            subMenuItem.MenuOptions.map((menuOption) => {
                                if (orderOption.order_menu_option_id === menuOption.option_id) {
                                    menuOption.Option.OptionValues.map((optionValue) => {
                                        if (orderOption.menu_option_value_id === optionValue.option_value_id) {
                                            menuOption.MenuOptionValues.map((upperOption) => {
                                                if (optionValue.option_value_id === upperOption.option_value_id) {
                                                    let flag = false;
                                                    filteredMenuOptions.map((filteredMenuOption) => {
                                                        if (filteredMenuOption.Option.option_id === menuOption.Option.option_id) {
                                                            filteredMenuOption.Option.OptionValues.push({...optionValue, order_quantity: orderOption.quantity / selectedOrderItem.quantity});
                                                            filteredMenuOption.MenuOptionValues.push({...upperOption, order_quantity: orderOption.quantity / selectedOrderItem.quantity});
                                                            flag = true;
                                                        }
                                                    })
                                                    if (!flag) {
                                                        filteredMenuOptions.push({
                                                            ...menuOption,
                                                            MenuOptionValues: [{...upperOption, order_quantity: orderOption.quantity / selectedOrderItem.quantity}],
                                                            Option: {
                                                                ...menuOption.Option,
                                                                OptionValues: [{...optionValue, order_quantity: orderOption.quantity / selectedOrderItem.quantity}]
                                                            }
                                                        })
                                                    }
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                    setFilteredOrderMenu((prevItems) => {
                        const updatedItems = [...prevItems];
                        updatedItems[index] = {
                            order_menu_id: selectedOrderItem.order_menu_id,
                            isModifying: true,
                            mainQty: selectedOrderItem.quantity,
                            ...subMenuItem,
                            MenuOptions: filteredMenuOptions
                        };
                        return updatedItems;
                    });
                }
            })
        })
    })
}