import React, {useEffect, useState} from 'react';
import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import UserService from "../../../../../../../services/UserService";
import {useDispatch, useSelector} from "react-redux";
import {setPOSUserLocationData} from "../../../../../../../redux/actions/pos/location";
import {setCustomerDetails} from "../../../../../../../redux/actions/pos/customer";

const UserSelectionComponent = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const autoCompleteLoading = open && options.length === 0;
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const customerType = useSelector(state => state.pos.customerType)

    useEffect(() => {
        let active = true;

        if (!autoCompleteLoading) {
            return undefined;
        }


        (async () => {
            const users = await UserService.getPOSCustomers("")
            if (active) {
                if (users.error){
                    setOptions([])
                } else {
                    setOptions(users.data.data);
                }
            }
        })();

        return () => {
            active = false;
        };
    }, [autoCompleteLoading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const handleAsyncAutoCompleteChange = async (e, newValue) => {
        setSelectedCustomer(newValue);
        if (!newValue) return;
        const userLocationData = {
            address: newValue.address ? newValue.address : '',
            long: newValue.longitude ? newValue.longitude : '',
            lat: newValue.latitude ? newValue.latitude: ''
        }

        const userPersonalData = {
            address: newValue.address ? newValue.address : '',
            name: newValue.name ? newValue.name : '',
            number: newValue.number ? newValue.number : '',
            email: newValue.email ? newValue.email : '',
            id: newValue.id ? newValue.id : ''
        }
        await dispatch(setPOSUserLocationData(userLocationData));
        await dispatch(setCustomerDetails(userPersonalData));
        console.log("Selected Customer", newValue);
    }

    const handleFieldChange = async (value) => {
        const users = await UserService.getPOSCustomers(value)
        if (users.error){
            setOptions([])
        } else {
            setOptions(users.data.data);
        }
    }

    return(
        <Autocomplete
            disabled={customerType === 'walk-in'}
            fullWidth
            size='small'
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => `${option.name ? option.name + ' - ' : ''}${option.email ? option.email : ''}${option.number ? ' - ' + option.number : ''}`}
            options={options}
            loading={autoCompleteLoading}
            onChange={(event, value) => handleAsyncAutoCompleteChange(event, value)}
            renderInput={(params) => (
                <TextField
                    onChange={(e)=> handleFieldChange(e.target.value)}
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {autoCompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    )
}

export default UserSelectionComponent;