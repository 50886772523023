import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import LocationService from "../../../../services/LocationService";
import {fetchMenuData} from "../../../../redux/actions/menus";
import {setPOSLocationData} from "../../../../redux/actions/pos/location";
import {Box, CircularProgress, Drawer, Stack} from "@mui/material";
import {green} from "@mui/material/colors";
import UpperSectionComponents from "./rightSections/upperSectionComponents";
import MainSectionComponents from "./rightSections/mainSectionComponent";

const ProductsDrawer = ({openDrawer}) => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.menu.menuData.loading);
    const [locationData, setLocationData] = useState(null)

    const getLocationMenuData = async () => {
        try {
            const data = LocationService.getLocation();
            setLocationData(data);
            await dispatch(fetchMenuData(data.slug));
            await dispatch(setPOSLocationData(data))
        } catch (err) {
            console.log("Get Location Data", err);
        }
    }

    useEffect(() => {
        getLocationMenuData();
    }, []);

    return(
        <Drawer anchor={"bottom"} open={openDrawer}>
            {loading ?
                <Stack sx={{backgroundColor: 'white', height: '100%', borderRadius: '0.25rem', width: '100%', padding: '0.5rem', rowGap: '0.5rem', alignItems: 'center', justifyContent: 'center'}}>
                    <CircularProgress sx={{color: green[500]}}/>
                </Stack>
                :
                <Stack sx={{backgroundColor: 'white', height: '100%', borderRadius: '0.25rem', width: '100%', padding: '0.5rem', rowGap: '0.5rem'}}>
                    <UpperSectionComponents />
                    <MainSectionComponents locationData={locationData}/>
                </Stack>
            }
        </Drawer>
    )
};

export default ProductsDrawer;