import CONSTANTS from "../../../../constants";
import OrderService from "../../../../services/OrderService";

export function setOrderType(order_type) {
    return (dispatch) => {
        dispatch({type: CONSTANTS.ACTIONS.POS.ORDER_TYPE, data: order_type});
    }
}

export function getPosOrderHistory () {
    return async dispatch => {
        try {
            dispatch({ type: CONSTANTS.ACTIONS.POS.GET_POS_ORDERS, pos_orders: [], loading: true });
            let pos_orders_history = await OrderService.getPOSOrdersHistoryService();
            pos_orders_history = pos_orders_history.data;
            dispatch({ type: CONSTANTS.ACTIONS.POS.GET_POS_ORDERS, pos_orders: pos_orders_history, loading: false });
        }
        catch (error) {
            console.log(error);
            dispatch({ type: CONSTANTS.ACTIONS.POS.GET_POS_ORDERS, pos_orders: [], loading: false });
        }
    }
}

export function setFilteredPosOrders(filteredOrders) {
    return dispatch => {
        dispatch({ type: CONSTANTS.ACTIONS.POS.SET_FILTERED_POS_ORDERS, pos_orders: filteredOrders });
    };
}

export function setSelectedOrder(order) {
    return dispatch => {
        dispatch({ type: CONSTANTS.ACTIONS.POS.SET_SELECTED_ORDER, order: order });
    }
}

export function setIsModifyOrder(isModifyOrder) {
    return dispatch => {
        dispatch({ type: CONSTANTS.ACTIONS.POS.SET_IS_MODIFY_ORDER, data: isModifyOrder });
    }
}

export function setRemoveItems(removeItems) {
    return dispatch => {
        dispatch({ type: CONSTANTS.ACTIONS.POS.SET_REMOVE_ITEMS, data: removeItems });
    }
}