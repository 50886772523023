import React, {useState} from 'react'
import {OutlinedInput} from "@mui/material";
import {Search} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {setMenuData} from "../../../../../redux/actions/pos/menu";

const SearchComponent = () => {
    const dispatch = useDispatch();
    const menu = useSelector((state)=> state.menu.menuData.data)
    const [searchText, setSearchText] = useState("");
    const [unFilteredData, setUnFilteredData] = useState(null);

    const handleSearch = (value) => {
        setSearchText(value);
        if (searchText === "") {
            setUnFilteredData(menu)
        }

        if (value === "") {
            dispatch(setMenuData(unFilteredData))
            return;
        }

        let finalData;
        if (unFilteredData) {
            const filteredData = unFilteredData.menu_data.map((item) => {
                const data = item.Menu.filter((menuItem) =>
                    menuItem.menu_name.toLowerCase().includes(value.toLowerCase())
                );
                if (data.length > 0) {
                    return { Category: item.Category, Menu: data };
                }
                return null;
            }).filter(item => item !== null);

            finalData = {
                menu_data: filteredData
            };
        } else {
            const filteredData = menu.menu_data.map((item) => {
                const data = item.Menu.filter((menuItem) =>
                    menuItem.menu_name.toLowerCase().includes(value.toLowerCase())
                );
                if (data.length > 0) {
                    return { Category: item.Category, Menu: data };
                }
                return null;
            }).filter(item => item !== null);

            finalData = {
                menu_data: filteredData
            };
        }

        dispatch(setMenuData(finalData))
    }

    return (
        <>
            <OutlinedInput
                value={searchText}
                startAdornment={<Search />}
                size='small'
                placeholder={'Name, Code or Category, ...'}
                fullWidth
                onChange={(e) => handleSearch(e.target.value)}
            />
        </>
    )
}

export default SearchComponent;