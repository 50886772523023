import React, {useState} from 'react';
import {IconButton, Stack, Tooltip} from "@mui/material";
import {
    ConfirmationNumber, Drafts, GridView, History, Language,
    Logout, MonitorOutlined, Notifications, PointOfSale,
    Print, ShoppingBagOutlined, DevicesFold
} from "@mui/icons-material";
import {useHistory} from "react-router-dom";
import RegisterDetailsModal from "../../mainComponents/navbar/registerDetailsModal";

const largeNavbarLeftIcons = [
    {
        icon: <Logout sx={{color: '#186218'}}/>,
        link: '/',
        helperText: "Logout"
    },
    {
        icon: <Language sx={{color: '#186218'}}/>,
        helperText: "Language"
    },
    {
        icon: <Drafts sx={{color: '#186218'}}/>,
        helperText: "Open Draft Sales"
    },
    {
        icon: <Print sx={{color: '#186218'}}/>,
        helperText: "Print Last Invoice"
    },
    {
        icon: <History sx={{color: '#186218'}}/>,
        helperText: "Recent Sales Invoice"
    },
    {
        icon: <ShoppingBagOutlined sx={{color: '#186218'}}/>,
        helperText: "Future Sales"
    },
    {
        icon: <GridView sx={{color: '#186218'}}/>,
        helperText: "Self/Online Orders"
    },
    {
        icon: <ConfirmationNumber sx={{color: '#186218'}}/>,
        helperText: "Reservations List"
    },
    {
        icon: <Notifications sx={{color: '#186218'}}/>,
        helperText: "Kitchen Notifications"
    },
    {
        icon: <PointOfSale sx={{color: '#186218'}}/>,
        helperText: "Register"
    },
    {
        icon: <DevicesFold sx={{color: '#186218'}}/>,
        helperText: "Dashboard"
    },
    {
        icon: <MonitorOutlined sx={{color: '#186218'}}/>,
        helperText: "Customer Display"
    },
    {
        icon: <MonitorOutlined sx={{color: '#186218'}}/>,
        helperText: "Order Status Screen"
    }
]

const LeftSideLeftIcons = () => {
    const [openModal, setOpenModal] = useState({
        openRegisterDetailModal: false
    })
    const history = useHistory();

    const handleIconClick = (index) => {
        if (index === 10) {
            history.push("/");
        } else if (index === 9) {
            setOpenModal({...openModal, openRegisterDetailModal: true})
        }
    }

    return(
        <>
            <Stack direction='row' sx={{alignItems: 'center'}}>
                {
                    largeNavbarLeftIcons.map((item, index) => (
                        <Tooltip title={item.helperText} key={index}>
                            <IconButton onClick={()=> handleIconClick(index)}>
                                {item.icon}
                            </IconButton>
                        </Tooltip>
                    ))
                }
            </Stack>
            {openModal.openRegisterDetailModal && <RegisterDetailsModal openModal={openModal} setOpenModal={setOpenModal}/>}
        </>
    )
}

export default LeftSideLeftIcons;