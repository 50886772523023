import React from 'react'
import {Stack, Button, IconButton, Tooltip, Typography} from "@mui/material";
import Cached from '@mui/icons-material/Cached';
import {blue, green, red, yellow} from "@mui/material/colors";

const buttonStyles = {
    backgroundColor: green[400],
    textTransform: 'capitalize',
    borderRadius: '0.25rem',
    padding: '0.35rem 0.5rem',
    lineHeight: '1.5rem',
    color: 'white',
    cursor: 'pointer',
    boxShadow: '0 0 5px rgba(0,0,0,0.25)',
    transition: '0.5s',
    '&:hover': {
        backgroundColor: green[600],
    }
}

const buttons = [
    {
        label: 'Online',
        backgroundColor: green[400],
        hoverColor: green[600],
    },
    {
        label: 'Vegetarian',
        backgroundColor: blue[400],
        hoverColor: blue[600],
    },
    {
        label: 'Beverages',
        backgroundColor: yellow[400],
        hoverColor: yellow[600],
    },
    {
        label: 'Combo',
        backgroundColor: red[400],
        hoverColor: red[600],
    }
]

const RightSideRightComponent = () => {
    return(
        <Stack direction='row' sx={{columnGap: '0.75rem', alignItems: 'center'}}>
            <Tooltip title={'0 sale(s) currently in local'}>
                <IconButton sx={{backgroundColor: green[400], '&:hover': {backgroundColor: green[600]}, borderRadius: '0.25rem', padding: '0.35rem', color: 'white'}}>
                    <Cached />
                </IconButton>
            </Tooltip>
            {
                buttons.map((button, index) => (
                    <Typography key={index} variant='body2' sx={{...buttonStyles, backgroundColor: button.backgroundColor, '&:hover': {backgroundColor: button.hoverColor}}}>
                        {button.label}
                    </Typography>
                ))
            }
        </Stack>
    )
}

export default RightSideRightComponent;