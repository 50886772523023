import CONSTANTS from "../../../../constants";

export function orderType(state = {order_type: 'Dine In'}, action) {
    if(action.type === CONSTANTS.ACTIONS.POS.ORDER_TYPE) {
        return { ...action.data };
    }

    return state
}

export function posOrdersHistory(state = { data: null, loading: true }, action) {
    switch (action.type) {
        case CONSTANTS.ACTIONS.POS.GET_POS_ORDERS:
            return { data: action.pos_orders, loading: action.loading };
        case CONSTANTS.ACTIONS.POS.SET_FILTERED_POS_ORDERS:
            return { data: action.pos_orders, loading: false };
        default:
            return state;
    }
}

export function selectedOrder(state = null, action) {
    if(action.type === CONSTANTS.ACTIONS.POS.SET_SELECTED_ORDER) {
        return { ...action.order };
    }
    return state
}

export function isModifyOrder(state = false, action) {
    if (action.type === CONSTANTS.ACTIONS.POS.SET_IS_MODIFY_ORDER) {
        return action.data;
    }
    return state
}

export function removeItemsList(state = [], action) {
    if (action.type === CONSTANTS.ACTIONS.POS.SET_REMOVE_ITEMS) {
        return action.data;
    }
    return state
}