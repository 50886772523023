import CONSTANTS from "../constants";

export const createSession = async ({
                                        table_id,
                                        location_id,
                                        session_date,
                                        start_time,
                                        end_time,
                                        table_name,
                                        session_owner,
                                        order_from
                                    }) => {
    let data = {
        table_id,
        location_id,
        session_date,
        start_time,
        end_time,
        table_name,
        session_owner,
        room_id: null,
        hotel_id: null,
        order_from: order_from
    };

    let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SESSION_ORDERS.CREATE_SESSION_ORDER}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { ...CONSTANTS.REQUEST_HEADERS },
    });
    console.warn("____", response.status);
    let statusCodedc = response.status;
    response = await response.json();
    response.status = statusCodedc;
    if (response) return response;
    throw new Error(`Could not Create Session: ${response}`);
};

export const getSessionStatus = async (session_id) => {
    try {
        const response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SESSION_ORDERS.GET_SESSION_STATUS(session_id)}`,{
            method: "GET",
            headers: { ...CONSTANTS.REQUEST_HEADERS },
        });

        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            throw new Error(`Failed to fetch session status: ${data.message}`);
        }
    } catch (error) {
        throw new Error(
            `An error occurred while fetching session status: ${error.message}`
        );
    }
};