import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {alpha, Button, MenuItem, Menu, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {KeyboardArrowDown} from "@mui/icons-material";
import {green} from "@mui/material/colors";
import {setSelectedCategory} from "../../../../../redux/actions/pos/category";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const buttonStyles = {
    textTransform: 'capitalize',
    borderRadius: '0.25rem',
    padding: '0.4rem 1rem',
    textAlign: 'center',
    lineHeight: '1.5rem',
    color: 'white',
    cursor: 'pointer',
    boxShadow: '0 0 5px rgba(0,0,0,0.25)',
    width: 'fit-content',
    height: '100%',
    backgroundColor: '#186218',
    '&:hover': {
        backgroundColor: '#1d7d1d'
    }
}

const MenuCategoriesDropDown = () => {
    const dispatch = useDispatch();
    const menuData = useSelector((state) => state.menu.menuData.data.menu_data);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectCategory = (category) => {
        dispatch(setSelectedCategory({selected: true, ...category}))
    }

    const handleSelectAllCategories = () => {
        dispatch(setSelectedCategory({selected: false}))
    }

    return(
        <>
            <Button
                sx={buttonStyles}
                aria-controls={open ? 'categories_menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDown />}
            >
                Categories
            </Button>
            <StyledMenu
                id="categories-menu"
                MenuListProps={{
                    'aria-labelledby': 'categories-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {menuData && menuData.length > 0 && (<MenuItem onClick={()=> handleSelectAllCategories()}>All</MenuItem>)}
                {(menuData && menuData.length > 0) ? (
                    menuData.map((item, index) => (
                        <MenuItem onClick={()=> handleSelectCategory(item)} key={index}>{item.Category.name}</MenuItem>
                    ))
                ) : (<MenuItem disabled> No Categories Found </MenuItem>)}
            </StyledMenu>
        </>
    )
}

export default MenuCategoriesDropDown;