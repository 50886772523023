import React from 'react'
import {Stack} from "@mui/material";
import RightSideLeftComponent from "../../components/navbar/largeScreen/rightSideLeftComponent";
import RightSideRightComponent from "../../components/navbar/largeScreen/rightSideRightComponent";

const LargeScreenNavbarRightSide = () => {
    return(
        <Stack direction='row' sx={{width: '50%', justifyContent: 'space-between', alignItems: 'center', minHeight: 'inherit'}}>
            <RightSideLeftComponent />
            <RightSideRightComponent />
        </Stack>
    )
}

export default LargeScreenNavbarRightSide;