import React from 'react'
import {Button, Stack} from "@mui/material";
import {grey} from "@mui/material/colors";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedCategory} from "../../../../../redux/actions/pos/category";

const buttonStyle = {
    backgroundColor: grey[200],
    textTransform: 'capitalize',
    borderRadius: '0.25rem',
    padding: '0.4rem 0.5rem',
    lineHeight: '1.5rem',
    fontSize: '0.85rem',
    color: 'black',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: grey[400],
        padding: '0.4rem 0.5rem'
    }
}

const CategorySelectionSection = () => {
    const dispatch = useDispatch();
    const menuData = useSelector((state)=> state.menu.menuData.data)
    const selectedCategory = useSelector(state => state.pos.selectedCategory);

    const handleSelectCategory = (category) => {
        dispatch(setSelectedCategory({selected: true, ...category}))
    }

    const handleSelectAllCategories = () => {
        dispatch(setSelectedCategory({selected: false}))
    }

    return(
        <Stack sx={{width: '140px', minWidth: '140px', maxWidth: '140px', rowGap: '0.5rem', height: '100%', overflow: 'auto', maxHeight: 'calc(100vh - 125px)', paddingRight: '5px'}}>
            <Button onClick={()=> handleSelectAllCategories()} fullWidth sx={{...buttonStyle, backgroundColor: !selectedCategory?.selected ? grey[400] : grey[200]}} variant='contained'>All</Button>
            {(menuData && menuData.menu_data.length) > 0 && (
                menuData.menu_data.map((item, index) => (
                    <Button
                        onClick={()=> handleSelectCategory(item)}
                        key={index} fullWidth
                        sx={{...buttonStyle, backgroundColor: (selectedCategory.selected ? (selectedCategory?.Category.category_id === item.Category.category_id ? grey[400] : grey[200]) : grey[200])}}
                        variant='contained'>{item.Category.name}</Button>
                ))
            )}
        </Stack>
    )
}

export default CategorySelectionSection;