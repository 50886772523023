import React from 'react';
import ButtonComponents
    from "../../../components/mainScreen/middleSectionComponents/topSectionComponents/buttonComponents";
import SelectorsComponent
    from "../../../components/mainScreen/middleSectionComponents/topSectionComponents/selectorsComponent";

const TopSectionComponents = () => {
    return(
        <>
            <ButtonComponents />
            <SelectorsComponent />
        </>
    )
}

export default TopSectionComponents;