import {green, grey} from "@mui/material/colors";

export const MAIN_GREEN_COLOR = '#186218'

export const filledInputFieldsStyle = {
    '& .MuiFilledInput-root': {
        backgroundColor: grey[100],
        borderRadius: '0.5rem',
        fontSize: '0.9rem',
        overflow: 'hidden',
        '&:before': {
            borderRadius: '0.5rem',
            borderBottom: 'none'
        },
        '&:after': {
            borderRadius: '0.5rem',
            borderBottom: `2px solid ${green[400]}`
        },
        '&:hover:before': {
            borderBottom: 'none'
        },
        '&.Mui-focused:before': {
            borderRadius: '0.5rem'
        },
        '&.Mui-focused:after': {
            borderRadius: '0.5rem',
            borderBottom: `2px solid ${green[400]}`
        },
    },
    '& label': {
        color: grey[500],
        fontSize: '0.9rem'
    },
    '& label.Mui-focused': {
        color: green[400],
    },
}

export const itemModal = {
    modalStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '1s',
        backdropFilter: 'blur(5px)'
    },

    mainStack: {
        height: {xs: '95vh', sm: '90vh'},
        width: {xs: '95vw', md: '50vw', lg: '35vw'},
        minWidth: {xs: '95vw', sm: '500px'},
        maxWidth: {xs: '95vw', sm: '500px'},
        backgroundColor: 'white',
        borderRadius: '5px'
    },

    priceSelectionButton: {
        backgroundColor: grey[200],
        textTransform: 'capitalize',
        color: 'black',
        borderRadius: '0.25rem',
        width: 'fit-content',
        paddingX: '1rem',
        paddingY: '0.35rem',
        transition: '0.5s',
        fontSize: '0.9rem'
    },

    addCartButton: {
        flex: 1,
        cursor: 'pointer',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0,0,0,0.25)',
        padding: '0.75rem 1.5rem',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: MAIN_GREEN_COLOR,
        textTransform: 'capitalize',
        fontSize: '16px',
        color: 'white'
    }
}

export const addCustomerModalStyles = {
    mainStack: {
        height: {xs: '95vh', sm: '90vh'},
        width: {xs: '95vw', md: '50vw', lg: '35vw'},
        minWidth: {xs: '95vw', sm: '500px'},
        maxWidth: {xs: '95vw', sm: '500px'},
        backgroundColor: 'white',
        borderRadius: '5px'
    },
}

export const selectTableModal = {
    mainStack: {
        height: 'fit-content',
        maxHeight: {xs: '95vh', md: '90vh'},
        width: {xs: '95vw', md: '650px', lg: '750px'},
        maxWidth: {xs: '95vw', md: '650px', lg: '750px'},
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '0.5rem'
    }
}

export const openBalanceModal = {
    mainStack: {
        height: 'fit-content',
        maxHeight: {xs: '95vw', md: '90vh'},
        width: {xs: '95vw', md: '60vw', lg: '35vw'},
        maxWidth: {xs: '95vw', sm: '600px', md: '850px'},
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '0.5rem'
    }
}

export const saveButtonStyle = {
    flex: 1,
    borderRadius: '4px',
    boxShadow: '0 0 5px rgba(0,0,0,0.25)',
    padding: '0.5rem 1.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: MAIN_GREEN_COLOR,
    textTransform: 'capitalize',
    fontSize: '16px',
    color: 'white',
    '&:hover' : {
        backgroundColor: MAIN_GREEN_COLOR
    }
}

export const orderDetailModalStyles = {
    mainStack: {
        height: 'fit-content',
        maxHeight: {xs: '95vh', sm: '90vh'},
        width: {xs: '95vw', md: '75vw', lg: '50vw'},
        minWidth: {xs: '95vw', sm: '500px', md: '800px', lg: '800px', xl: '800px'},
        maxWidth: {xs: '95vw', sm: '500px'},
        backgroundColor: 'white',
        borderRadius: '4px'
    },

    saveButton: {
        borderRadius: '4px',
        boxShadow: '0 0 5px rgba(0,0,0,0.25)',
        padding: '0.5rem 1rem',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: MAIN_GREEN_COLOR,
        textTransform: 'capitalize',
        fontSize: '14px',
        color: 'white',
        '&:hover': {
            backgroundColor: MAIN_GREEN_COLOR
        },
    },

    closeButton: {
        borderRadius: '4px',
        boxShadow: '0 0 5px rgba(0,0,0,0.25)',
        padding: '0.5rem 1rem',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'black',
        textTransform: 'capitalize',
        fontSize: '14px',
        color: 'white',
        '&:hover': {
            backgroundColor: 'black'
        },
    }
}

export const finalOrderModalStyles = {
    mainStack: {
        height: 'fit-content',
        maxHeight: {xs: '95vh', sm: '90vh'},
        minWidth: {xs: '95vw', sm: '500px', md: '850px', lg: '1100px'},
        maxWidth: {xs: '95vw', sm: '500px', md: '850px', lg: '1100px'},
        backgroundColor: 'white',
        borderRadius: '4px'
    },

    saveButton: {
        borderRadius: '4px',
        boxShadow: '0 0 5px rgba(0,0,0,0.25)',
        padding: '0.5rem 1rem',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: MAIN_GREEN_COLOR,
        textTransform: 'capitalize',
        fontSize: '14px',
        color: 'white',
        '&:hover': {
            backgroundColor: MAIN_GREEN_COLOR
        },
    },

    closeButton: {
        borderRadius: '4px',
        boxShadow: '0 0 5px rgba(0,0,0,0.25)',
        padding: '0.5rem 1rem',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'black',
        textTransform: 'capitalize',
        fontSize: '14px',
        color: 'white',
        '&:hover': {
            backgroundColor: 'black'
        },
    }
}