import React from 'react'
import {Divider, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {useSelector} from "react-redux";

const RightSideLeftComponent = () => {
    const locationData = useSelector((state) => state.pos.locationData);

    return(
        <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.5rem', minHeight: 'inherit'}}>
            <Divider orientation='vertical' variant="middle" flexItem sx={{borderRightColor: grey[600]}}/>
            <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '800', color: '#186218'}}>{locationData?.name ? locationData.name : ''}</Typography>
            <Divider orientation='vertical' variant="middle" flexItem sx={{borderRightColor: grey[600]}}/>
        </Stack>
    )
}

export default RightSideLeftComponent;